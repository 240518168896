//@ts-nocheck
import { useEffect, useState } from "react";
import _ from "lodash";
import { Popover, PopoverButton, PopoverGroup, PopoverPanel } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import {
  ArrowDownwardRounded,
  CalendarTodayRounded,
  LocationOnRounded,
  PersonSearchRounded,
  ArrowUpwardRounded,
  ExpandLessRounded,
  ExpandMoreRounded,
  ContrastRounded,
  CircleRounded,
  CancelOutlined,
  CircleOutlined,
  CloseRounded,
  FilterAltRounded,
} from "@mui/icons-material";
import { twJoin, twMerge } from "tailwind-merge";
import Alert from "./Alert";
import StrengthIndicator from "./StrengthIndicator";
import SourceItem from "./SourceItem";
import { snakeCaseToProperCase } from "@/utils";

const AdverseMediaV2 = ({
  events,
  perpetratorProfileList,
  input,
}: {
  events: any;
  perpetratorProfileList: any;
  input: {
    first_name: string;
    last_name: string;
    middle_name: string | null;
    country_of_residence?:
      | {
          original_country_input: string;
          country_name: string;
          alpha_2_country_code: string;
          alpha_3_country_code: string;
          numeric_country_code: string;
        }
      | string;
    country_of_nationality?:
      | {
          original_country_input: string;
          country_name: string;
          alpha_2_country_code: string;
          alpha_3_country_code: string;
          numeric_country_code: string;
        }
      | string;
    address_country?:
      | {
          original_country_input: string;
          country_name: string;
          alpha_2_country_code: string;
          alpha_3_country_code: string;
          numeric_country_code: string;
        }
      | string;
    date_of_birth: string;
    individual_age: number;
    today_date: string;
  };
}) => {
  // Create a map for easy access to events by id
  const eventsMap = events?.reduce((acc, event) => {
    acc[event?.id] = event;
    return acc;
  }, {});

  return (
    <div className="w-full flex flex-col gap-y-7 text-xs overflow-hidden flex-wrap">
      <div className="w-full flex flex-col gap-y-5">
        {perpetratorProfileList.map((perpetrator, index) => {
          return <PerpetratorCard key={index} perpetrator={perpetrator} eventsMap={eventsMap} input={input} />;
        })}
      </div>
    </div>
  );
};

export const TopicChip = ({ topic, className }: { topic: string; className?: string }) => {
  return (
    <div
      className={twMerge(
        "flex justify-center items-center bg-slate-50 border border-slate-300 font-medium text-slate-700 rounded-full px-2 py-1",
        className ? className : "",
      )}
    >
      <span className="line-clamp-1 capitalize">{snakeCaseToProperCase(topic)}</span>
    </div>
  );
};

const PerpetratorCard = ({ perpetrator, eventsMap, input }: { perpetrator: any; eventsMap: any; input: any }) => {
  const [showMatchInfo, setShowMatchInfo] = useState(true);
  const [showAllArticles, setShowAllArticles] = useState(false);

  const matchRating = perpetrator.perpetrator_review.match_rating.match;
  const matchReason = perpetrator.perpetrator_review.match_rating.reason;
  const countryMatch = perpetrator.perpetrator_review.country_match;
  const firstNameMatch = perpetrator.perpetrator_review.first_name_match;
  const lastNameMatch = perpetrator.perpetrator_review.last_name_match;
  const perpetratorFullName = perpetrator.full_name;
  const ageMatch = perpetrator.perpetrator_review.age_match;
  const event = eventsMap[perpetrator.associated_adverse_media_event_id];

  return (
    <div className="py-4 border border-slate-300 rounded-lg flex flex-col w-full overflow-hidden flex-wrap">
      <div className="flex flex-col gap-y-4">
        <div
          className={twJoin(
            "relative flex flex-wrap gap-y-2 gap-x-2 justify-between pb-3 px-4",
            showMatchInfo ? "" : "border-b border-slate-300",
          )}
        >
          <div className="flex items-center gap-x-2">
            <PersonSearchRounded sx={{ fontSize: "1rem" }} className="w-4 h-4 text-slate-900" />
            <span className="text-sm font-semibold">{perpetrator.full_name}</span>
          </div>

          {matchRating && (
            <div
              className="place-self-end w-fit max-w-full flex gap-4"
              role="button"
              onClick={() => setShowMatchInfo(!showMatchInfo)}
            >
              <StrengthIndicator matchRating={matchRating} />
            </div>
          )}
        </div>
      </div>

      <div className="relative flex flex-col gap-y-4">
        <button
          className={twJoin(
            "z-30 absolute left-[calc(50%-1rem)] rounded-full ring-1 ring-slate-300 h-5 w-5 bg-white",
            showMatchInfo ? "-bottom-2.5" : "-top-2.5",
          )}
          onClick={() => setShowMatchInfo(!showMatchInfo)}
        >
          {showMatchInfo ? (
            <ExpandLessRounded sx={{ fontSize: "1.25rem" }} />
          ) : (
            <ExpandMoreRounded sx={{ fontSize: "1.25rem" }} />
          )}
        </button>

        {showMatchInfo && (
          <div className={twJoin("flex flex-col gap-y-4 px-4 pb-2", showMatchInfo ? "border-b border-slate-300" : "")}>
            <Alert
              level={
                matchRating === "strong_match" ? "failure" : matchRating === "partial_match" ? "partial" : "caution"
              }
              message={matchReason}
            />
            <div className="w-0 min-w-full overflow-x-auto">
              <table className="w-0 min-w-full table-auto leading-normal">
                <thead>
                  <tr className="text-left">
                    <th className="px-1.5"></th>
                    <th className="px-1.5">Found Perpetrator</th>
                    <th className="px-1.5 text-slate-500">Customer Information</th>
                    <th className="px-1.5"></th>
                  </tr>
                </thead>
                <tbody className="w-full border-separate border-spacing-2 divide-y divide-slate-300">
                  <tr className="text-left">
                    <td className="whitespace-nowrap px-1.5 py-2">
                      <div className="flex gap-x-2 items-center">
                        <PersonSearchRounded sx={{ fontSize: "1rem" }} />
                        <span>First Name</span>
                      </div>
                    </td>
                    <td
                      className={twJoin(
                        "truncate px-1.5 py-2",
                        firstNameMatch?.exact_match
                          ? "text-red-700 font-semibold"
                          : firstNameMatch?.partial_match
                            ? "text-orange-600"
                            : firstNameMatch?.mismatch
                              ? "text-yellow-800"
                              : "text-slate-600",
                      )}
                    >
                      <span className={firstNameMatch?.exact_match ? "font-semibold" : ""}>
                        {firstNameMatch?.name ? firstNameMatch.name : perpetratorFullName}
                      </span>
                    </td>
                    <td className="text-slate-400 truncate px-1.5 py-2">
                      <span className="text-slate-400"> vs. {input.first_name}</span>
                    </td>
                    <td className="px-1.5 py-2 text-right">
                      <div className="inline-flex">
                        <>
                          {firstNameMatch?.exact_match ? (
                            <MatchPill matchRating="strong_match" />
                          ) : firstNameMatch?.partial_match ? (
                            <MatchPill matchRating="partial_match" />
                          ) : firstNameMatch?.mismatch ? (
                            <MatchPill matchRating="mismatch" />
                          ) : (
                            <MatchPill matchRating="no_match" />
                          )}
                        </>
                      </div>
                    </td>
                  </tr>
                  <tr className="text-left">
                    <td className="whitespace-nowrap px-1.5 py-2">
                      <div className="flex gap-x-2 items-center">
                        <PersonSearchRounded sx={{ fontSize: "1rem" }} />
                        <span>Last Name</span>
                      </div>
                    </td>
                    <td
                      className={twJoin(
                        "truncate px-1.5 py-2",
                        lastNameMatch?.exact_match
                          ? "text-red-700 font-semibold"
                          : lastNameMatch?.partial_match
                            ? "text-orange-600"
                            : lastNameMatch?.mismatch
                              ? "text-yellow-800"
                              : "text-slate-600",
                      )}
                    >
                      <span className={lastNameMatch?.exact_match ? "font-semibold" : ""}>
                        {lastNameMatch?.name ? lastNameMatch.name : perpetratorFullName}
                      </span>
                    </td>
                    <td className="text-slate-400 truncate px-1.5 py-2">
                      <span className="text-slate-400"> vs. {input.last_name}</span>
                    </td>
                    <td className="py-2 px-1.5 text-right">
                      <div className="inline-flex">
                        <>
                          {lastNameMatch?.exact_match ? (
                            <MatchPill matchRating="strong_match" />
                          ) : lastNameMatch?.partial_match ? (
                            <MatchPill matchRating="partial_match" />
                          ) : lastNameMatch?.mismatch ? (
                            <MatchPill matchRating="mismatch" />
                          ) : (
                            <MatchPill matchRating="no_match" />
                          )}
                        </>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="whitespace-nowrap px-1.5 py-2">
                      <div className="flex gap-x-2 items-center">
                        <CalendarTodayRounded sx={{ fontSize: "1rem" }} />
                        <span>Age Today</span>
                      </div>
                    </td>
                    <td
                      className={twJoin(
                        "truncate px-1.5 py-2",
                        ageMatch?.exact_match
                          ? "text-red-700 font-semibold"
                          : ageMatch?.partial_match
                            ? "text-orange-600"
                            : ageMatch?.mismatch
                              ? "text-yellow-800"
                              : "text-slate-600",
                      )}
                    >
                      {ageMatch?.age ? `${ageMatch?.age} years old` : "Not Found"}
                    </td>
                    <td className="text-slate-400 truncate px-1.5 py-2">
                      <span className="text-slate-400"> vs. {input.individual_age}</span>
                    </td>
                    <td className="px-1.5 py-2 text-right">
                      <div className="inline-flex">
                        <MatchPill
                          matchRating={
                            ageMatch?.exact_match
                              ? "strong_match"
                              : ageMatch?.partial_match
                                ? "partial_match"
                                : ageMatch?.mismatch
                                  ? "mismatch"
                                  : "no_match"
                          }
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="whitespace-nowrap px-1.5 py-2">
                      <div className="flex gap-x-2 items-center">
                        <LocationOnRounded sx={{ fontSize: "1rem" }} />
                        <span>Location</span>
                      </div>
                    </td>
                    <td
                      className={twJoin(
                        "text-wrap px-1.5 py-2",
                        countryMatch?.exact_match
                          ? "text-red-700 font-semibold"
                          : countryMatch?.partial_match
                            ? "text-orange-600 font-semibold"
                            : countryMatch?.mismatch
                              ? "text-yellow-800"
                              : "text-slate-600",
                      )}
                    >
                      {countryMatch?.countries?.length > 0
                        ? countryMatch.countries
                            .map((country) =>
                              country !== null && typeof country === "object" && "country_name" in country
                                ? country.country_name
                                : country,
                            )
                            .join(", ")
                        : "Not Found"}
                    </td>
                    <td className="text-slate-400 truncate px-1.5 py-2">
                      <span className="text-slate-400">
                        {" "}
                        vs.{" "}
                        {input.country_of_residence?.country_name ??
                          input.address_country?.country_name ??
                          input.country_of_nationality?.country_name ??
                          "Unknown"}
                      </span>
                    </td>
                    <td className="py-2 px-1.5 text-right ">
                      <div className="inline-flex">
                        <MatchPill
                          matchRating={
                            countryMatch?.exact_match
                              ? "strong_match"
                              : countryMatch?.partial_match
                                ? "partial_match"
                                : countryMatch?.mismatch
                                  ? "mismatch"
                                  : "no_match"
                          }
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
      <div className="px-4 flex flex-col mt-5 gap-y-4">
        {/** Here goes the Adverse Event info  */}
        <div className="flex flex-col gap-y-1">
          {event?.title && <span className="text-sm font-medium">{event.title}</span>}
          {event?.summary && <span className="text-slate-700">{event.summary}</span>}
        </div>

        {/** Here goes the list of Topics  */}
        {event.topics?.length ? (
          <div className="flex flex-wrap items-center gap-2 text-slate-900 font-medium">
            <span>Topics</span>
            {event.topics.map((topic, index) => (
              <TopicChip key={index} topic={topic} />
            ))}
          </div>
        ) : null}

        <div className="grid grid-cols-2 gap-6">
          {event.when && (
            <div className="flex flex-col gap-2">
              <span className="font-medium">Year of Event</span>
              <span className="text-slate-700">{event.when}</span>
            </div>
          )}
          {event.perpetrator.associated_countries?.length > 0 && (
            <div className="flex flex-col gap-2">
              <span className="font-medium">Related Locations</span>
              <span className="text-slate-700">
                {event.perpetrator.associated_countries
                  .map((country) =>
                    country !== null && typeof country === "object" && "country_name" in country
                      ? country.country_name
                      : country,
                  )
                  .join(", ")}
              </span>
            </div>
          )}
        </div>

        <div className="flex flex-col gap-y-2">
          {showAllArticles
            ? event?.sources?.map((source) => (
                <SourceItem
                  key={source.id}
                  url={source.url}
                  title={source.title}
                  sourceName={source.url ? new URL(source.url).hostname : ""}
                  summary={source.description}
                  text={source.text_content}
                  hasPhoto={source.has_images_of_perpetrator}
                  wasRemoved={false}
                  who={source.metadata.full_name}
                  when={source.metadata.year_of_the_crime}
                  what={source.metadata.topics}
                  where={source.metadata.associated_countries}
                  perpetratorAge={source.metadata.age}
                />
              ))
            : null}
        </div>

        {event?.sources?.length > 0 && (
          <button
            type="button"
            disabled={!event?.sources?.length}
            className="box-border inline-flex items-center gap-x-1.5 rounded-lg px-3 py-2 w-full text-center justify-center border border-solid border-slate-300"
            onClick={() => setShowAllArticles(!showAllArticles)}
          >
            {/** Here we need to calculate the number of articles related to this Event */}
            {showAllArticles ? (
              <>
                {`Hide ${event?.sources?.length ?? 0} Article${event?.sources?.length === 1 ? "" : "s"}`}
                <ArrowUpwardRounded sx={{ fontSize: "1rem" }} className="-mr-0.5 h-5 w-5" aria-hidden="true" />
              </>
            ) : (
              <>
                <span>{`Show ${event?.sources?.length ?? 0} Article${event?.sources?.length === 1 ? "" : "s"}`}</span>
                <ArrowDownwardRounded sx={{ fontSize: "1rem" }} className="-mr-0.5 h-5 w-5" aria-hidden="true" />
              </>
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export const MatchPill = ({ matchRating }) => {
  return (
    <div
      className={twJoin(
        "w-fit text-xs px-2 py-1 flex gap-x-1 rounded-full truncate items-center font-semibold",
        matchRating === "strong_match"
          ? "text-red-700 bg-red-100 border border-red-200"
          : matchRating === "partial_match"
            ? "text-orange-700 bg-orange-100 border border-orange-200 "
            : matchRating === "mismatch"
              ? "text-yellow-800 bg-yellow-100 border border-yellow-200"
              : "text-slate-700 bg-slate-100 border border-slate-200",
      )}
    >
      {matchRating === "strong_match" ? (
        <CircleRounded sx={{ fontSize: "1rem" }} />
      ) : matchRating === "partial_match" ? (
        <ContrastRounded sx={{ fontSize: "1rem" }} />
      ) : matchRating === "mismatch" ? (
        <CancelOutlined sx={{ fontSize: "1rem" }} />
      ) : (
        <CircleOutlined sx={{ fontSize: "1rem" }} />
      )}
      {matchRating === "strong_match"
        ? "Exact match"
        : matchRating === "partial_match"
          ? "Partial match"
          : matchRating === "mismatch"
            ? "Mismatch"
            : "Missing"}
    </div>
  );
};

export default AdverseMediaV2;
