import { useEffect } from "react";
import useAuth from "@parcha/hooks/useAuth";
import { usePostHog } from "posthog-js/react";
import { Navigate } from "react-router-dom";
import { useKoala } from "@getkoala/react";

export default function ProtectedRoute({ children }: { children: React.ReactNode }) {
  const posthog = usePostHog();
  const { isLoading, isAuthenticated, user } = useAuth();
  const { koala, ready } = useKoala();

  useEffect(() => {
    if (user && isAuthenticated && ready) {
      const tenant = user.userTenants && user.userTenants.length > 0 ? user.userTenants[0] : null;
      koala.identify({
        email: user.email,
        name: user.name,
        $account: { name: tenant?.tenantName ?? undefined },
      });
    }
  }, [isAuthenticated, ready, user]);

  useEffect(() => {
    if (user && isAuthenticated) {
      posthog?.identify(user.userId, {
        email: user.email,
      });
    }
  }, [user, isAuthenticated, posthog]);

  if (isLoading) return null;
  if (!isAuthenticated || !user) return <Navigate to="/signin" />;

  return <>{children}</>;
}
