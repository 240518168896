// @ts-nocheck
import { LocationOnRounded } from "@mui/icons-material";
import AddressValue from "./AddressValue";
import Alert from "./Alert";
import FindingsLoadingIndicator from "./FindingsLoadingIndicator";

const AddressesFindings = ({ checkResult, hideHeader = false }) => {
  const Header = (
    <div className="flex items-center gap-x-2">
      <LocationOnRounded sx={{ fontSize: "1.25rem" }} className="text-slate-700" />
      <span className="text-lg font-semibold">Addresses</span>
    </div>
  );

  if (!["complete", "error"].includes(checkResult.status)) {
    return (
      <div className="flex flex-col gap-y-4">
        {Header}
        <div className="place-self-center">
          <FindingsLoadingIndicator message="Running Addresses check..." />
        </div>
      </div>
    );
  }

  if (!checkResult?.verified_data) {
    return null;
  }

  const dedupedAddresses = checkResult.verified_data?.found_addresses?.reduce((acc, address) => {
    const key =
      `${address.address?.street_1}-${address.address?.street_2}-${address.address?.city}-${address.address?.country_code}`.toLowerCase();
    if (!acc[key]) {
      acc[key] = address;
    }
    return acc;
  }, {});

  const uniqueAddresses = Object.values(dedupedAddresses || {});

  return (
    <div className="w-full text-sm overflow-hidden flex flex-col gap-y-5">
      {!hideHeader && Header}
      <Alert message={checkResult.answer} level={checkResult.passed ? "success" : "failure"} />
      {uniqueAddresses?.length > 0 && (
        <div className="grid grid-cols-2 gap-x-3 gap-y-2 w-full">
          {uniqueAddresses
            .filter((address) => address.address && address.address.city)
            .map((address, index) => (
              <FoundAddressEntry key={index} data={address.address} />
            ))}
        </div>
      )}
    </div>
  );
};

const FoundAddressEntry = ({ data }) => {
  const addressLine1 = `${data?.street_1 ?? ""}${data?.street_2 ? `, ${data.street_2}` : ""}`;
  const addressLine2 = `${data?.city ?? ""}${data?.state ? `, ${data.state}` : ""}${
    data?.country_code ? `, ${data.country_code}` : ""
  }${data?.postal_code ? `, ${data.postal_code}` : ""}`;
  const gMapsAddress = encodeURIComponent(addressLine1 + " " + addressLine2).replaceAll(" ", "+");

  return (
    <div className="text-xs border border-slate-200 p-4 flex flex-col gap-y-2 rounded-lg">
      <span className="font-medium text-xs">
        {data?.address_type === "operations"
          ? "Operation Address"
          : data?.address_type === "incorporation"
            ? "Incorporation Address"
            : "Found Address"}
      </span>
      <div className="flex w-full gap-x-3">
        <div className="w-1/2">
          <AddressValue
            street1={data?.street_1}
            street2={data?.street_2}
            city={data?.city}
            state={data?.state}
            postalCode={data?.postal_code}
            countryCode={data?.country_code}
          />
        </div>
        <a
          href={`https://www.google.com/maps/search/?api=1&query=${gMapsAddress}`}
          target="_blank"
          rel="noreferrer"
          className="block w-1/2 h-[7.5rem]"
        >
          <img
            src={`https://maps.googleapis.com/maps/api/staticmap?center=${gMapsAddress}&zoom=13&size=1200x600&maptype=roadmap&markers=color:red%7C${gMapsAddress}&key=AIzaSyA-5WSnfQmqQYax2f6JUb0_UFxTCVotbes`}
            className="object-cover max-w-[400px] place-self-center w-full h-full rounded-md"
          />
        </a>
      </div>
    </div>
  );
};

export default AddressesFindings;
