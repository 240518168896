import useAuth from "@parcha/hooks/useAuth";
import React from "react";
import { PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";

export default function ProtectedRoute({ children }: PropsWithChildren) {
  const { isLoading, isAuthenticated, user, endpoints } = useAuth();

  // Auth checks have not been completed.
  if (isLoading) return null;
  const userIsParcha = user?.userTenants?.some((tenant) => tenant.tenantName === "Parcha");
  if (!isAuthenticated || !user || !userIsParcha) return <Navigate to="/signin" />;
  // Pass endpoints to children.
  const childrenWithProps = React.Children.map(children, (child) => {
    return React.cloneElement(child as React.ReactElement<any>, { user, endpoints });
  });

  return <>{childrenWithProps}</>;
}
