import { RouterProvider, createBrowserRouter, createRoutesFromElements, Route, Navigate } from "react-router-dom";
import "./App.css";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import { AuthProvider } from "@descope/react-sdk";
import { PostHogConfig } from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import SignIn from "./components/Signin";
import { DESCOPE_PROJECT_ID } from "./constants/vars";
import SignOut from "./components/SignOut";
import ProtectedRoute from "./components/ProtectedRoute";

import ParchaProtectedRoute from "./components/ParchaProtectedRoute";
import JobInspector from "./components/JobInspector";
import ErrorBoundary from "./components/ErrorBoundary";
import { ErrorProvider } from "./contexts/ErrorProvider";
import WorkerInspector from "./components/WorkerInspector";
import ChecksOverview from "./components/ChecksOverview";
import Checks from "./components/IndividualChecks";
import Remediation from "./components/RemediationPage";
import { V2HomePage } from "./components/V2_Home_Page";
import Layout from "./components/TailwindComponents/Layout";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ApiKeysManager from "./components/ApiKeysManager";
import BetaRedirect, { loader as betaRedirectLoader } from "./components/TailwindComponents/BetaRedirect";
import Applications from "./components/ApplicationsPage";
import SOFKYBRemediation from "./components/SOFKYBRemediation";
import SOFKYCRemediation from "./components/SOFKYCRemediation";
import EmbedChecksViewByCaseId from "./components/EmbedChecksViewByCaseId";
import EmbedChecksViewByJobId from "./components/EmbedChecksViewByJobId";
import CaseRedirect, { loader as caseRedirectLoader } from "./components/TailwindComponents/CaseRedirect";
import PersonaLoader, { loader as personaLoader } from "./components/PersonaLoader";
import IncorporationDocRemediation from "./components/IncorporationDocRemediation";
import { KoalaProvider } from "@getkoala/react";

// changes to the theme here will affect all components that use the theme
// see https://mui.com/customization/default-theme/ for more info
const theme = createTheme({
  palette: {
    primary: {
      main: "#5D5FEF",
    },
    secondary: {
      main: "#E9EEF4",
    },
    // Add more customization here
  },
  typography: {
    fontFamily: "Inter, Arial, sans-serif",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
  },
});

const postHogOptions: Partial<PostHogConfig> = {
  api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
  person_profiles: "identified_only",
};

const App = () => {
  const routes = createRoutesFromElements(
    <>
      <Route path="/embed_by_jobid" element={<EmbedChecksViewByJobId />} />
      <Route path="/embed_by_caseid" element={<EmbedChecksViewByCaseId />} />
      <Route path="/signin" element={<SignIn />} />
      <Route path="/signout" element={<SignOut />} />
      <Route path="/parchaAdmin" element={<Navigate to="/parchaAdmin/jobInspector" />}></Route>
      <Route
        path="/parchaAdmin/jobInspector"
        element={
          <ParchaProtectedRoute>
            <JobInspector />
          </ParchaProtectedRoute>
        }
      ></Route>
      <Route
        path="/parchaAdmin/workerInspector"
        element={
          <ParchaProtectedRoute>
            <WorkerInspector />
          </ParchaProtectedRoute>
        }
      ></Route>
      <Route path="/sof-kyc-remediation" element={<SOFKYCRemediation />} />

      <Route path="/remediation" element={<Remediation />} />
      <Route path="/incorporation-doc-remediation" element={<IncorporationDocRemediation />} />
      <Route path="/sof-kyb-remediation" element={<SOFKYBRemediation />} />

      <Route
        path="/"
        element={
          <ProtectedRoute>
            <Layout />
          </ProtectedRoute>
        }
      >
        <Route path="beta/*" element={<BetaRedirect />} loader={betaRedirectLoader} />
        <Route path="jobs" element={<V2HomePage />}>
          <Route path=":routeEndpoint" element={<V2HomePage />}>
            <Route path=":routeJobId" element={<V2HomePage />}></Route>
          </Route>
        </Route>

        <Route path="/checks-overview" element={<ChecksOverview />} />

        <Route path="/cases/:agentKey/:caseId" element={<CaseRedirect />} loader={caseRedirectLoader} />

        <Route path="/api_keys" element={<ApiKeysManager />} />

        <Route path="/checks" element={<Checks />} />
        <Route path="/applications" element={<Applications />}>
          <Route path=":applicationId" element={<Applications />} />
        </Route>
        <Route path="/persona/:agentKey/:caseId" element={<PersonaLoader />} loader={personaLoader} />
      </Route>
    </>,
  );

  const router = createBrowserRouter(routes);

  return (
    <ThemeProvider theme={theme}>
      <ErrorProvider>
        <AuthProvider projectId={DESCOPE_PROJECT_ID}>
          <ErrorBoundary>
            <PostHogProvider apiKey={import.meta.env.VITE_PUBLIC_POSTHOG_KEY} options={postHogOptions}>
              <KoalaProvider publicApiKey={import.meta.env.VITE_KOALA_API_KEY}>
                <RouterProvider router={router} />
              </KoalaProvider>
            </PostHogProvider>
          </ErrorBoundary>
        </AuthProvider>
      </ErrorProvider>
    </ThemeProvider>
  );
};

export default App;
