import { styled } from "@mui/material";
import { DataGrid, DataGridProps } from "@mui/x-data-grid";

export const ParchaDataGrid = styled(DataGrid)<DataGridProps>(() => ({
  "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
    outline: "none !important",
  },
  "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus": {
    outline: "none !important",
  },
}));
