// @ts-nocheck
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useParchaApi } from "@/hooks/useParchaApi";
import WrappedValue from "./TailwindComponents/WrappedValue";
import { toTitleCase } from "@/utils";
import { formatDistanceToNowStrict } from "date-fns";
import { twJoin } from "tailwind-merge";
import { Icon } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircleRounded";
import CancelRounded from "@mui/icons-material/CancelRounded";
import Alert from "./TailwindComponents/Alert";
import Progress from "@/components/ui/progress";
import KYBProofOfAddressCard from "./TailwindComponents/KYBProofOfAddressCard";
import IncorporationDocument from "./TailwindComponents/IncorporationDocument";
import Accordion from "./V2_Home_Page/Accordion";
import OwnershipDocument from "./TailwindComponents/OwnershipDocument";
import { Endpoint, EndpointsAndTenant, FraudVerificationData } from "@/types";

const name: { [k: string]: string } = {
  "kyb.business_ownership_verification_tool": "Business Ownership Verification Tool",
  "kyb.incorporation_document_verification": "Business Registration Verification Tool",
  "kyb.proof_of_address_verification": "Proof of Address Verification Tool",
};

const iconsMap: { [k: string]: string } = {
  "kyb.business_ownership_verification_tool": "request_quote",
  "kyb.incorporation_document_verification": "description",
  "kyb.proof_of_address_verification": "markunread_mailbox",
};

const ApplicationsPage = () => {
  const parchaApi = useParchaApi();
  const [applicants, setApplicants] = useState([]);
  const [latestApplicationState, setLatestApplicationState] = useState(null);
  const [endpoints, setEndpoints] = useState<Endpoint[]>([]);
  const [endpoint, setEndpoint] = useState<Endpoint | null>(null);

  const { applicationId } = useParams();

  const fetchEndpoints = async () => {
    const customerAgents = await parchaApi.getCustomerAgents();
    const { endpoints } = customerAgents as EndpointsAndTenant;
    setEndpoints(endpoints);
  };

  /** FETCHERS END */

  // Step 1: Fetch all endpoints to populate the dropdown
  useEffect(() => {
    fetchEndpoints();
  }, []);

  // Step 2: When we have the endpoints and/or agentKey, we select the current endpoint
  useEffect(() => {
    if (endpoints) {
      const remediationEndpoint = endpoints.find((endpoint) => endpoint.agentKey === "remediation-v1") ?? null;
      setEndpoint(remediationEndpoint);
    }
  }, [endpoints]);

  useEffect(() => {
    if (!applicationId || !endpoint) return;

    const getApplicationState = async () => {
      const latestApplicationState = await parchaApi.getLatestApplicationState(
        endpoint.endpointUrl,
        applicationId,
        endpoint.agentKey,
      );
      setLatestApplicationState(latestApplicationState);
    };

    getApplicationState();
  }, [endpoint, applicationId]);

  useEffect(() => {
    const fetchApplicants = () => {
      if (!endpoint) return;

      parchaApi.getApplicants(endpoint?.endpointUrl, endpoint?.agentKey).then((res) => setApplicants(res));
    };
    fetchApplicants();
  }, [parchaApi, endpoint]);

  return (
    <div className="grid grid-rows-[61px_1fr]">
      <div className="grid grid-cols-[320px_1fr]">
        <div className="border-r border-slate-200 divide-y divide-slate-200">
          {applicants?.map((applicant: any) => (
            <ApplicantItem key={applicant.id} applicant={applicant} isSelected={applicant.id === applicationId} />
          ))}
        </div>
        {latestApplicationState && (
          <div className="grid grid-rows-[86px_1fr]">
            <div className="flex flex-col gap-y-2 bg-slate-50 px-10 py-5">
              <span className="text-md font-semibold">{latestApplicationState.self_attested_data.business_name}</span>
              <div className="flex gap-x-1 items-center text-slate-500 text-xs">
                <span>{toTitleCase(latestApplicationState.application_status)}</span>
                <span>•</span>
                <span>{`${formatDistanceToNowStrict(new Date(`${latestApplicationState.updated_at}Z`))} ago`}</span>
              </div>
            </div>
            <div className="flex flex-col gap-y-3 px-10 py-5 h-[calc(100vh-86px)] overflow-y-auto">
              {latestApplicationState?.current_check_results?.map((checkResult) => {
                const didPass = ["true", "pass", "passed", "yes"].includes(
                  checkResult.passed?.toString().toLowerCase(),
                );
                const level = didPass ? "success" : "failure";
                const commandResult = checkResult.status_messages.find((sm: any) => sm.event === "command_results");
                const payload = commandResult?.content?.output?.payload;
                const args = commandResult?.content?.args;

                return (
                  <div
                    key={checkResult.id}
                    className="flex flex-col gap-y-2 p-5 border border-slate-300 rounded-lg text-slate-900"
                  >
                    <Accordion
                      title={
                        <div className="w-full flex justify-between">
                          <div className="flex gap-x-3 items-center">
                            <Icon sx={{ fontSize: "1.25rem" }} className="material-icons-round text-slate-400">
                              {iconsMap[checkResult.command_id]}
                            </Icon>
                            <span className="font-semibold text-md">{name[checkResult.command_id]}</span>
                          </div>
                          {checkResult.passed !== null ? (
                            didPass ? (
                              <CheckCircleIcon sx={{ fontSize: "1.25rem" }} className="text-green-600" />
                            ) : (
                              <CancelRounded sx={{ fontSize: "1.25rem" }} className="text-red-500" />
                            )
                          ) : null}
                        </div>
                      }
                      main={<Alert message={checkResult.answer} level={level} />}
                      content={
                        <>
                          {commandResult?.content?.output?.payload?.type === "ProofOfAddressCheckResult" ? (
                            <div className="flex justify-center">
                              <KYBProofOfAddressCard
                                address={
                                  payload.verified_address
                                    ? {
                                        street1: payload.verified_address.street_1,
                                        street2: payload.verified_address.street_2,
                                        city: payload.verified_address.city,
                                        state: payload.verified_address.state,
                                        postalCode: payload.verified_address.postal_code,
                                        countryCode: payload.verified_address.country_code,
                                      }
                                    : {
                                        street1: "",
                                        street2: "",
                                        city: "",
                                        state: "",
                                        postalCode: "",
                                        countryCode: "",
                                      }
                                }
                                businessName={payload.verified_name}
                                level={level}
                                proofOfAddressDocuments={
                                  checkResult.passed
                                    ? checkResult.verification_data.proof_of_address_documents.map((entry: any) => ({
                                        fileName: entry.document.file_name,
                                        url: entry.document.url,
                                        summary: entry.summary,
                                        fraudVerificationData: entry.fraud_verification_data,
                                      }))
                                    : checkResult.verification_data.invalid_docs.map((entry: any) => ({
                                        fileName: entry.document.file_name,
                                        url: entry.document.url,
                                        summary: entry.summary,
                                        fraudVerificationData: entry.fraud_verification_data,
                                      }))
                                }
                              />
                            </div>
                          ) : null}
                          {commandResult?.content?.output?.payload?.type ===
                          "KYBIncorporationDocumentVerificationResult" ? (
                            <div className="flex justify-center">
                              <IncorporationDocument
                                level={level}
                                address={
                                  payload.verified_business_address
                                    ? {
                                        street1: payload.verified_business_address.street_1,
                                        street2: payload.verified_business_address.street_2,
                                        city: payload.verified_business_address.city,
                                        state: payload.verified_business_address.state,
                                        postalCode: payload.verified_business_address.postal_code,
                                        countryCode: payload.verified_business_address.country_code,
                                      }
                                    : {
                                        street1: "",
                                        street2: "",
                                        city: "",
                                        state: "",
                                        postalCode: "",
                                        countryCode: "",
                                      }
                                }
                                businessActivity={payload.verified_business_activity}
                                companyName={payload.verified_business_name}
                                incorporationDate={payload.verified_incorporation_date}
                                incorporationDocuments={args.verification_data.documented_incorporation_information.map(
                                  (entry: any) => ({
                                    fileName: entry.document.file_name, // Adjust based on actual property names
                                    url: entry.document.url,
                                    summary: entry.summary,
                                    fraudVerificationData: entry.fraud_verification_data,
                                  }),
                                )}
                              />
                            </div>
                          ) : null}

                          {commandResult?.content?.output?.payload?.type ===
                          "KYBBusinessOwnershipVerificationResult" ? (
                            <div className="flex justify-center">
                              <OwnershipDocument
                                level={level}
                                businessOwners={
                                  payload.verified_business_owners?.map( 
                                    (owner: {
                                      full_name: string;
                                      percentage: number | null;
                                      shares: number | null;
                                    }) => ({
                                      fullName: owner.full_name,
                                      percentage: owner.percentage,
                                      shares: owner.shares,
                                    }),
                                  ) || []
                                }
                                ownershipDocuments={args?.verification_data?.documented_ownership
                                  ?.filter((entry: any) => entry.document)
                                  .map(
                                    (record: {
                                      document: { file_name: string; url: string };

                                      fraud_verification_data?: FraudVerificationData;
                                      summary?: string;
                                    }) => ({
                                      fileName: record.document.file_name,
                                      url: record.document.url,
                                      summary: record.summary,
                                      fraudVerificationData: record.fraud_verification_data,
                                    }),
                                  )}
                              />
                            </div>
                          ) : null}
                        </>
                      }
                      preventExpandCollapse={false}
                      expanded={false}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const ApplicantItem = ({ applicant, isSelected }: any) => (
  <Link
    to={`/applications/${applicant.id}`}
    className={twJoin("flex flex-col p-5 text-xs", isSelected ? "bg-slate-50" : null)}
  >
    <div className="flex flex-col gap-y-1">
      <div className="flex justify-between">
        <span className="font-semibold text-sm">{applicant.business_name}</span>
        {applicant.application_decision ? <WrappedValue level="normal" text={applicant.application_decision} /> : null}
      </div>
      <div className="flex gap-x-1 text-slate-500">
        <span>{toTitleCase(applicant.application_status)}</span>
        <span>•</span>
        <span>{`${formatDistanceToNowStrict(new Date(`${applicant.created_at}Z`))} ago`}</span>
      </div>
    </div>
  </Link>
);

export default ApplicationsPage;
