import React, { useState, useRef, useEffect, ReactNode } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ExpandMoreRounded } from "@mui/icons-material";
import { twJoin } from "tailwind-merge";

interface SmartTab {
  id: string;
  label: string;
  icon: ReactNode | null;
}

interface SmartTabsProps {
  tabs: SmartTab[];
  selectedTab: string;
  onSelect: (tabId: string) => void;
}

const SmartTabs: React.FC<SmartTabsProps> = ({ tabs, selectedTab, onSelect }) => {
  const [visibleTabs, setVisibleTabs] = useState<SmartTab[]>([]);
  const [hiddenTabs, setHiddenTabs] = useState<SmartTab[]>([]);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleResize = () => {
      if (!containerRef.current) return;

      const containerWidth = containerRef.current.offsetWidth;
      const children = Array.from(containerRef.current.children) as HTMLElement[];
      let totalWidth = 0;
      let visibleCount = 0;

      for (const child of children) {
        totalWidth += child.offsetWidth;
        if (totalWidth > containerWidth) break;
        visibleCount++;
      }

      setVisibleTabs(tabs.slice(0, visibleCount - 1));
      setHiddenTabs(tabs.slice(visibleCount - 1));
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [tabs]);

  return (
    <div className="text-xs z-30 h-full flex items-center gap-x-0.5" ref={containerRef}>
      {visibleTabs.map((tab) => (
        <button
          key={tab.id}
          onClick={() => onSelect(tab.id)}
          className={twJoin(
            "box-border h-full px-4 py-2 whitespace-nowrap",
            selectedTab === tab.id ? "border-solid border-t-0 border-x-0 border-b-2 border-brand-purple" : "",
          )}
        >
          {tab.label} {tab.icon ? tab.icon : null}
        </button>
      ))}

      {hiddenTabs.length > 0 && (
        <Menu as="div" className="w-full h-full relative inline-block text-left">
          <Menu.Button className="h-full items-center inline-flex justify-center w-full bg-white rounded">
            More
            <ExpandMoreRounded className="w-5 h-5 ml-2 -mr-1" aria-hidden="true" />
          </Menu.Button>

          <Transition
            as={React.Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="z-10 w-[250px] absolute right-0 w-56 origin-top-right bg-white divide-y divide-slate-200 rounded shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              {hiddenTabs.map((tab) => (
                <Menu.Item key={tab.id}>
                  <button
                    onClick={() => onSelect(tab.id)}
                    className={`${
                      selectedTab === tab.id ? "bg-brand-purple text-white" : "text-slate-900"
                    } group flex justify-between rounded-md items-center w-full px-2 py-4 overflow-hidden box-border`}
                  >
                    {tab.label} {tab.icon ? tab.icon : null}
                  </button>
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </Menu>
      )}
    </div>
  );
};

export default SmartTabs;
