import { redirect } from "react-router-dom";

export const loader = ({ request }: { request: Request }) => {
  const url = new URL(request.url);
  const paths = url.pathname.split("/");

  if (paths.length > 2) {
    const endpointUrl = paths[2];

    if (paths.length > 3) {
      const jobId = paths[3];
      return redirect(`/jobs/${endpointUrl}/${jobId}`);
    }
    return redirect(`/jobs/${endpointUrl}`);
  }
  return redirect("/jobs");
};

const BetaRedirect = () => {
  return null;
};

export default BetaRedirect;
