// @ts-nocheck
import FindingsSection from "./FindingsSection";
import {
  AttachMoneyRounded,
  BusinessRounded,
  EmailRounded,
  FactoryRounded,
  ImageSearchRounded,
  LocationOnRounded,
  PeopleAltRounded,
} from "@mui/icons-material";
import DetailValue from "./DetailValue";
import SourcesTag from "./SourcesTag";
import Alert from "./Alert";
import { RefreshRounded } from "@mui/icons-material";
import { useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import FindingsLoadingIndicator from "./FindingsLoadingIndicator";

const BusinessBasicProfile = ({
  checkResult,
  hideHeader = false,
  officialBusinessWebsite,
  onTryAgainClick,
  type = "business",
}) => {
  const Header = (
    <div className="flex items-center gap-x-2">
      <BusinessRounded sx={{ fontSize: "1.25rem" }} className="text-slate-700" />
      <span className="text-lg font-semibold">
        {type === "business" ? "General Business Information" : type === "entity" ? "General Entity Information" : ""}
      </span>
    </div>
  );

  if (!["complete", "error"].includes(checkResult.status) && checkResult.metadata?.business_name) {
    return (
      <div className="flex flex-col gap-y-4">
        {Header}
        <div className="place-self-center">
          <FindingsLoadingIndicator
            message={`Running Business Basic Profile on ${checkResult.metadata.business_name}...`}
          />
        </div>
      </div>
    );
  }

  if (!checkResult?.verified_data) {
    return null;
  }

  const bizDescriptionFoundObj =
    checkResult.verified_data.business_description || checkResult.verified_data.business_description_found;
  const bizNameFound = checkResult.verified_data.business_name || checkResult.verified_data.business_name_found;
  const bizIndustryFound =
    checkResult.verified_data.business_industry || checkResult.verified_data.business_industry_found;
  const bizLocationsFound =
    checkResult.verified_data.business_locations || checkResult.verified_data.business_locations_found;
  const bizProductsAndServicesFound =
    checkResult.verified_data.business_products_and_services ||
    checkResult.verified_data.business_products_and_services_found;
  const bizCustomersFound = checkResult.verified_data.customers || checkResult.verified_data.customers_found;
  const bizContactInfoFound = checkResult.verified_data.contact_info || checkResult.verified_data.contact_info_found;
  const bizBackedByFound = checkResult.verified_data.backed_by || checkResult.verified_data.backed_by_found;
  const bizInvestmentReceivedFound =
    checkResult.verified_data.investment_received || checkResult.verified_data.investment_received_found;
  const bizEstimatedNumEmployeesFound =
    checkResult.verified_data.estimated_range_num_employees ||
    checkResult.verified_data.estimated_range_num_employees_found;
  const bizSocialLinksFound =
    checkResult.verified_data.business_social_links || checkResult.verified_data.business_social_links_found;
  const bizProfileLinksFound =
    checkResult.verified_data.business_profile_links || checkResult.verified_data.business_profile_links_found;

  return (
    <div className="w-full text-sm">
      <>
        {checkResult.verified_data && !bizDescriptionFoundObj ? (
          <div className="flex flex-col items-center justify-center p-10 bg-slate-50 rounded-[1.25rem] text-center">
            <p className="text-lg font-bold text-slate-700 mb-2">
              We were unable to generate a business profile with the provided information
            </p>
            <p className="text-sm text-slate-500 mb-4">
              Please consider adding a business description and / or alternative URLs.
            </p>
            <button
              type="button"
              className="text-sm relative flex justify-center items-center rounded-md bg-brand-purple p-2 h-4 border border-solid border-brand-purple text-white focus:z-10 "
              onClick={() => onTryAgainClick((prev) => !prev)}
            >
              <RefreshRounded className="mr-2 h-5 w-5" aria-hidden="true" />
              Try Again
            </button>
          </div>
        ) : (
          <div className="flex flex-col gap-y-5">
            {!hideHeader && Header}
            <Alert message={checkResult.answer} level={checkResult.passed ? "success" : "failure"} />
            <div className="flex flex-col md:flex-row p-5 gap-6 justify-between rounded-md bg-slate-50">
              <div className="flex w-2/3 flex-col gap-3">
                <div className="flex flex-col gap-y-1">
                  <span className="font-semibold">{bizNameFound?.business_name}</span>
                  <div className="flex items-center gap-x-1">
                    {bizProfileLinksFound?.map(
                      (link: { source_id: string; snippet: string; url: string | null }, index) => {
                        if (!link.url) return null;

                        return (
                          <>
                            {index > 0 && <span className="text-slate-700"> • </span>}
                            <a href={link.url} target="_blank" rel="noreferrer" className="text-brand-purple">
                              {link.url.includes("crunchbase.com")
                                ? "Crunchbase"
                                : link.url.includes("linkedin.com")
                                  ? "LinkedIn"
                                  : /^(https?:\/\/)?(www\.)?x\.com(\/|$)/.test(link.url)
                                    ? "X"
                                    : bizNameFound?.business_name}
                            </a>
                          </>
                        );
                      },
                    )}
                  </div>
                </div>
                <div className="flex flex-col gap-y-2 h-full">
                  {bizLocationsFound?.map((location) => (
                    <DetailValue
                      value={`${location?.address?.city ? `${location.address.city}, ` : ""} ${
                        location.address?.country_code ?? ""
                      } ${location.address?.postal_code ?? ""}`}
                      icon={<LocationOnRounded sx={{ fontSize: "1.25rem" }} />}
                    />
                  ))}
                  {bizIndustryFound?.industry && (
                    <DetailValue
                      value={bizIndustryFound.industry}
                      icon={<FactoryRounded sx={{ fontSize: "1.25rem" }} />}
                    />
                  )}
                  <DetailValue
                    value={
                      <span>
                        {bizBackedByFound?.backed_by ?? ""}
                        {bizInvestmentReceivedFound?.investment_received
                          ? `, ${bizInvestmentReceivedFound?.investment_received} raised`
                          : ""}
                      </span>
                    }
                    icon={
                      bizInvestmentReceivedFound?.investment_received ? (
                        <AttachMoneyRounded sx={{ fontSize: "1.25rem" }} />
                      ) : null
                    }
                  />
                  {bizEstimatedNumEmployeesFound?.estimated_num_employees && (
                    <DetailValue
                      value={bizEstimatedNumEmployeesFound.estimated_num_employees}
                      icon={<PeopleAltRounded sx={{ fontSize: "1.25rem" }} />}
                    />
                  )}
                  {bizContactInfoFound?.email?.email && (
                    <DetailValue
                      value={bizContactInfoFound.email.email}
                      icon={<EmailRounded sx={{ fontSize: "1.25rem" }} />}
                    />
                  )}
                </div>
                {(bizSocialLinksFound?.length > 0 || bizProfileLinksFound?.length > 0) && (
                  <SourcesTag
                    sources={[...(bizSocialLinksFound || []), ...(bizProfileLinksFound || [])].reduce(
                      (acc: { url: string; type: string }[], link: { url: string }) => {
                        if (!link.url) return acc;

                        if (link.url.includes("linkedin.com")) {
                          acc.push({ url: link.url, type: "LinkedIn" });
                        } else if (link.url.includes("facebook.com")) {
                          acc.push({ url: link.url, type: "Facebook" });
                        } else if (link.url.includes("crunchbase.com")) {
                          acc.push({ url: link.url, type: "Crunchbase" });
                        } else if (link.url.includes("twitter.com")) {
                          acc.push({ url: link.url, type: "Twitter" });
                        } else if (/^(https?:\/\/)?(www\.)?x\.com(\/|$)/.test(link.url)) {
                          acc.push({ url: link.url, type: "X" });
                        } else if (link.url.includes("instagram.com")) {
                          acc.push({ url: link.url, type: "Instagram" });
                        } else if (link.url.includes("youtube.com")) {
                          acc.push({ url: link.url, type: "YouTube" });
                        } else {
                          acc.push({ url: link.url, type: "Other" });
                        }
                        return acc;
                      },
                      [],
                    )}
                  />
                )}
              </div>
              {officialBusinessWebsite && (
                <div className="w-full md:w-1/3">
                  <WebsiteCard
                    websiteLabel={bizNameFound?.business_name}
                    websiteUrl={officialBusinessWebsite?.webpage_url}
                    screenshotUrl={officialBusinessWebsite?.screenshot_url}
                    visualSummary={officialBusinessWebsite?.visual_summary}
                  />
                </div>
              )}
            </div>
            {bizDescriptionFoundObj?.description && (
              <FindingsSection title="Business Description" content={bizDescriptionFoundObj?.description} />
            )}
            {bizProductsAndServicesFound?.products_and_services && (
              <FindingsSection
                title="Product and Services"
                content={<p>{bizProductsAndServicesFound.products_and_services}</p>}
              />
            )}
            {bizCustomersFound?.length && (
              <FindingsSection
                title="Current Customers"
                content={
                  <>
                    <ol className="list-decimal list-outside px-4 py-2">
                      {bizCustomersFound?.map((entry: { customer: string }) => <li>{entry.customer}</li>)}
                    </ol>
                  </>
                }
              />
            )}
          </div>
        )}
      </>
    </div>
  );
};

type WebsiteViewProps = {
  websiteUrl: string;
  websiteLabel: string;
  screenshotUrl?: string | null;
  visualSummary?: string | null;
};

const WebsiteCard = ({ websiteUrl, websiteLabel, screenshotUrl, visualSummary }: WebsiteViewProps) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  if (
    ((!websiteUrl || !websiteUrl.length) && (!websiteLabel || !websiteLabel.length)) ||
    websiteUrl?.startsWith("https://www.google.com/search")
  ) {
    return null;
  }

  return (
    <div className="flex flex-col text-xs">
      <div className="flex justify-center">
        <div className="flex flex-col border border-white rounded-lg p-[0.125rem] gap-[0.125rem]">
          {websiteUrl?.startsWith("https://www.google.com/search") ? null : websiteUrl ? (
            <a href={websiteUrl} target="_blank" rel="noreferrer">
              {screenshotUrl ? (
                <img className="h-[200px] w-[400px] rounded-t-md object-cover" src={screenshotUrl} />
              ) : (
                <NoImageFound />
              )}
            </a>
          ) : screenshotUrl ? (
            <img
              className="rounded-md object-fit"
              src={screenshotUrl}
              onError={({ currentTarget }) => (currentTarget.style.display = "none")}
            />
          ) : (
            <div className="w-full min-w-full grow">
              <NoImageFound />
            </div>
          )}
          {websiteLabel ? (
            <div className="bg-slate-100 text-center whitespace-nowrap block text-ellipsis text-slate-700 py-2 px-5 rounded-b-md line-clamp-1 overflow-hidden">
              {websiteLabel}
            </div>
          ) : null}
        </div>
      </div>
      {visualSummary && (
        <div className="mt-2 flex justify-center">
          <button
            className="text-blue-600 hover:underline flex items-center"
            onClick={() => setIsAccordionOpen(!isAccordionOpen)}
          >
            {isAccordionOpen ? <ChevronUpIcon className="w-4 h-4" /> : <ChevronDownIcon className="w-4 h-4" />}
            <span className="mr-1">{isAccordionOpen ? "Hide visual description" : "Show visual description"}</span>
          </button>
        </div>
      )}
      {isAccordionOpen && visualSummary && (
        <div className="px-4 py-2 mt-2 text-xs text-slate-700 bg-slate-50 rounded-md text-center">{visualSummary}</div>
      )}
    </div>
  );
};

const NoImageFound = () => {
  return (
    <div className="flex flex-col items-center justify-center gap-y-2 p-12 text-slate-500 bg-slate-200 rounded-t-md">
      <ImageSearchRounded sx={{ fontSize: "2rem" }} />
      <span className="font-semibold">No website image found...</span>
    </div>
  );
};

export default BusinessBasicProfile;
