import { useEffect } from "react";
import { Link, NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import FactCheckIconOutlined from "@mui/icons-material/FactCheckOutlined";
import InsertChartFilled from "@mui/icons-material/InsertChart";
import InsertChartOutlined from "@mui/icons-material/InsertChartOutlined";
import CodeIcon from "@mui/icons-material/Code";
import CodeIconOutlined from "@mui/icons-material/CodeOutlined";
import ParchaLogo from "./ParchaLogo";
import { SmartToy, SmartToyOutlined } from "@mui/icons-material";

const baseLinkClasses = "flex justify-center items-center w-12 h-12 rounded";

const Layout = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/jobs");
    }
  }, [location.pathname]);

  return (
    <div className="grid grid-cols-[80px,_1fr] h-full overflow-hidden">
      <div className="flex flex-col">
        <div className="bg-brand-purple text-white" id="logo">
          <Link to="/jobs">
            <ParchaLogo />
          </Link>
        </div>
        <div className="w-full h-full py-5 bg-slate-900 text-white text-[1.5rem] flex flex-col justify-between">
          <nav className="flex-grow-1 flex flex-col items-center">
            <ul role="navigation" className="flex flex-col items-center w-12 h-12 gap-4 text-slate-400">
              <li className="w-full flex justify-center">
                <StyledNavLink to="/jobs">
                  {({ isActive }: { isActive: boolean }) => {
                    return isActive ? (
                      <FactCheckIcon fontSize="inherit" />
                    ) : (
                      <FactCheckIconOutlined fontSize="inherit" />
                    );
                  }}
                </StyledNavLink>
              </li>
              <li className="w-full flex justify-center">
                <StyledNavLink to="/checks-overview">
                  {({ isActive }: { isActive: boolean }) => {
                    return isActive ? (
                      <InsertChartFilled fontSize="inherit" />
                    ) : (
                      <InsertChartOutlined fontSize="inherit" />
                    );
                  }}
                </StyledNavLink>
              </li>
              <li className="w-full flex justify-center">
                <StyledNavLink to="/api_keys">
                  {({ isActive }: { isActive: boolean }) => {
                    return isActive ? <CodeIcon fontSize="inherit" /> : <CodeIconOutlined fontSize="inherit" />;
                  }}
                </StyledNavLink>
              </li>
              {/* <li className="w-full flex justify-center">
                <StyledNavLink to="/checks">
                  {({ isActive }: { isActive: boolean }) => {
                    return isActive ? <SmartToy fontSize="inherit" /> : <SmartToyOutlined fontSize="inherit" />;
                  }}
                </StyledNavLink>
              </li> */}
            </ul>
          </nav>
          <div className="w-full flex justify-center">
            <Link to="/signout" className="p-3 text-slate-400 hover:bg-slate-800 hover:text-white">
              <LogoutIcon className="text-center" fontSize="inherit" />
            </Link>
          </div>
        </div>
      </div>
      <main>
        <Outlet />
      </main>
    </div>
  );
};

const StyledNavLink = ({ to, children }: { to: string; children: (props: { isActive: boolean }) => JSX.Element }) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        isActive
          ? `text-white ${baseLinkClasses}`
          : `text-slate-500 hover:text-white hover:bg-slate-800 ${baseLinkClasses}`
      }
    >
      {children}
    </NavLink>
  );
};

export default Layout;
