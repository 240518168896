import { Box, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridRowSelectionModel, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { AgentRun, StatusType } from "@parcha/types";
import { FC, useState, useEffect } from "react";
import { ParchaDataGrid } from "../ParchaDataGrid";
import StatusBox from "../StatusBox";
import RecommendationBox from "../RecommendationBox";
import { formatDistanceToNowStrict } from "date-fns";

interface JobStatusTableProps {
  rows: AgentRun[];
  rowSelectionModel?: GridRowSelectionModel;
  setRowSelectionModel: (newModel: GridRowSelectionModel) => void;
  setDisplayedAgentRun?: (id: string) => void;
  onRowClick?: (params: any) => void;
  enableCheckboxSelection?: boolean;
  showAdminColumns?: boolean;
}

const JobStatusTable: FC<JobStatusTableProps> = ({
  rows,
  rowSelectionModel,
  setRowSelectionModel,
  setDisplayedAgentRun,
  onRowClick,
  enableCheckboxSelection,
  showAdminColumns,
}) => {
  // component logic here
  const [tableData, setTableData] = useState<AgentRun[]>([]);

  useEffect(() => {
    if (rows) {
      setTableData(rows);
    }
  }, [rows]);

  let columns: GridColDef[] = [
    {
      field: "case_id",
      headerName: "Case Info",
      flex: 1.5,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => {
        const item: AgentRun = params.row as AgentRun;
        const name =
          item.input_payload?.self_attested_data?.name ||
          item.input_payload?.self_attested_data?.business_name ||
          item.input_payload?.self_attested_data?.registered_business_name ||
          `${item.input_payload?.self_attested_data.first_name}${
            item.input_payload?.self_attested_data.middle_name
              ? ` ${item.input_payload?.self_attested_data.middle_name} `
              : " "
          }${item.input_payload?.self_attested_data.last_name}`;
        const caseId = item.case_id || item.input_payload?.id;
        return (
          <>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", padding: "100px 0" }}>
              {name && (
                <Typography variant="body1" sx={{ fontSize: "16px", fontWeight: 600 }}>
                  <span style={{ color: "#5D5FEF" }}>{name}</span>
                </Typography>
              )}
              <Typography variant="body1" sx={{ fontSize: "14px" }}>
                Case ID: <span style={{ color: "#5D5FEF" }}>{caseId}</span>
              </Typography>
              <Typography variant="body1" sx={{ fontSize: "14px" }}>
                Job ID: {item.id}
              </Typography>
            </Box>
          </>
        );
      },
    },

    { field: "owner_id", headerName: "User Email", flex: 1, align: "left", headerAlign: "left" },
    {
      field: "agent_key",
      headerName: "Agent Key",
      flex: 1,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "endpoint",
      headerName: "Endpoint",
      flex: 1,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "progress",
      headerName: "Progress",
      renderCell: (params: GridRenderCellParams<AgentRun>) => (
        <StatusBox status={(params.row as AgentRun).status as StatusType} progress={params.value as number} />
      ),
      flex: 1,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "recommendation",
      headerName: "Recommendation",
      flex: 0.75,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => {
        return params.value && <RecommendationBox recommendation={params.value} />;
      },
    },
    {
      field: "timestamp",
      headerName: "Timestamp",
      width: 150,
      align: "right",
      headerAlign: "right",
      renderCell: (params) => {
        const date = new Date(params.value * 1000);
        return formatDistanceToNowStrict(date, { addSuffix: true });
      },
    },
    {
      field: "total_tokens",
      headerName: "Total tokens",
      flex: 1,
      align: "right",
      headerAlign: "right",
      renderCell: (params) => {
        return params.value && params.value.toLocaleString();
      },
    },
    {
      field: "total_cost_str",
      headerName: "Job Cost",
      flex: 1,
      align: "right",
      headerAlign: "right",
      renderCell: (params) => {
        return params.value;
      },
    },
  ];

  if (!showAdminColumns) {
    columns = columns.filter(
      (column) => !["owner_email", "endpoint", "agent_key", "total_cost_str", "total_tokens"].includes(column.field),
    );
  }

  return (
    <>
      {tableData && (
        <ParchaDataGrid
          rowHeight={80}
          checkboxSelection={enableCheckboxSelection}
          slots={{ toolbar: showAdminColumns ? GridToolbarQuickFilter : undefined }}
          rows={tableData}
          columns={columns}
          sx={{
            borderColor: "#f9f9f9",
            borderTop: "none",
            "& .Mui-selected": {
              borderTop: "1px solid #5D5FEF",
              borderBottom: "1px solid #5D5FEF",
            },

            "& .MuiDataGrid-withBorderColor": {
              borderTop: "1px solid #f9f9f9",
              borderBottom: "1px solid #f9f9f9",
            },

            boxShadow: 0,
            "--unstable_DataGrid-radius": "0",
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
            },
          }}
          onRowClick={onRowClick}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
            if (newRowSelectionModel && newRowSelectionModel.length > 0 && setDisplayedAgentRun)
              setDisplayedAgentRun(newRowSelectionModel[0].toString());
          }}
          rowSelectionModel={rowSelectionModel}
          initialState={{
            sorting: {
              sortModel: [{ field: "timestamp", sort: "desc" }],
            },
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[5, 10, 25]}
          columnHeaderHeight={showAdminColumns ? undefined : 0}
        />
      )}
    </>
  );
};

export default JobStatusTable;
