// @ts-nocheck
import _ from "lodash";
import { twJoin } from "tailwind-merge";
import { useContext, useState, useEffect, useMemo } from "react";
import { Agent, AgentRun, CommandResult } from "@/types";
import FeedbackContext from "@/contexts/FeedbackContext";
import { useParchaApi } from "@parcha/hooks/useParchaApi";
import { Box } from "@mui/material";
import {
  EmailRounded,
  HourglassTopRounded,
  ReplayCircleFilled,
  ViewAgendaOutlined,
  ViewHeadlineOutlined,
} from "@mui/icons-material";
import FinalOutput from "@/components/TailwindComponents/FinalOutput";
import FullWidthTabs from "@/components/TailwindComponents/FullWidthTabs";
import CheckContainer from "../CheckContainer";
import styles from "./styles.module.css";
import { toTitleCase } from "@/utils";
import StatusUI from "@/components/Chat/StatusUI";

import { ErrorRounded } from "@mui/icons-material";
import Findings from "../FindingsView";
import AssociatedEntityFindings from "../AssociatedEntityFindings";

type Tab = {
  name: string;
  id: string;
  count: number;
};

const TABS: Tab[] = [
  { name: "Alerts", id: "alerts", count: 0 },
  { name: "Findings", id: "findings", count: 0 },
  { name: "Business", id: "business", count: 0 },
  { name: "Individuals", id: "individuals", count: 0 },
  { name: "Entities", id: "entities", count: 0 },
];

const AgentRunChecksView = ({
  agent,
  agentRun,
  endpoint,
  isLegacyJob = false,
  setSelectedSidePanelTabValue,
  onRetryJobClick,
  onTryAgainClick,
  showTabular,
  setShowTabular,
}: {
  agent: Agent | null;
  agentRun: AgentRun | null;
  endpoint: Endpoint;
  isLegacyJob?: boolean;
  setSelectedSidePanelTabValue: (val: string) => void;
  onRetryJobClick: () => void;
  onTryAgainClick: () => void;
}): JSX.Element => {
  if (!agentRun || !agent) return;

  const contextFeedbackData = useContext(FeedbackContext);
  const [selectedTabId, setSelectedTabId] = useState<string>("");
  const [selectedAgentInstanceId, setSelectedAgentInstanceId] = useState<string | null>(null);
  const [alertsMessages, setAlertsMessages] = useState({});
  const [mainAgentStatusMessages, setMainAgentStatusMessages] = useState([]);
  const [businessMessages, setBusinessMessages] = useState({});
  const [individualsMessages, setIndividualMessages] = useState({});
  const [associatedEntitiesMessages, setAssociatedEntitiesMessages] = useState({});
  const [jobFeedbackData, setJobFeedbackData] = useState<any>(null);
  const [availableLocations, setAvailableLocations] = useState([]);
  const [availableGenders, setAvailableGenders] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedGenders, setSelectedGenders] = useState([]);
  const [jobMetadata, setJobMetadata] = useState(null);
  const parchaApi = useParchaApi();

  useEffect(() => {
    setSelectedTabId("");
    setMainAgentStatusMessages([]);
    setAlertsMessages({});
    setBusinessMessages({});
    setIndividualMessages({});
    setAssociatedEntitiesMessages({});
  }, [agentRun.id]);

  useEffect(() => {
    if (!agentRun.id) {
      return;
    }

    parchaApi.getJobMetadata(endpoint.endpointUrl, agentRun.id).then((res) => {
      setJobMetadata(res);
      const isThereWebPresenceCheck = res?.some((check) => check.command_id === "kyb.web_presence_check") ?? false;
      if (agentRun.status === "complete" && isThereWebPresenceCheck) {
        setSelectedTabId("findings");
      }
    });
  }, [agentRun.id]);

  useEffect(() => {
    const adverseMediaCommandResult = agentRun.status_messages.find(
      (message) =>
        message.event === "command_results" && message.content.command_id === "kyc.adverse_media_screening_check_v2",
    );
    const pepCommandResult = agentRun.status_messages.find(
      (message) => message.event === "command_results" && message.content.command_id === "kyc.pep_screening_check_v2",
    );

    const adverseMediaLocations =
      _.flatten(
        adverseMediaCommandResult?.content?.output?.payload.perpetrator_profiles.map((profile) =>
          profile.associated_countries.map((country) => country.country_name),
        ),
      ) || [];

    const pepLocations =
      _.flatten(
        pepCommandResult?.content?.output?.payload?.verified_pep_hits.map((pepProfile) => {
          if (pepProfile.associated_countries) {
            return pepProfile.associated_countries.map((country) => country.country_name);
          } else if (pepProfile.pep_profile.associated_countries) {
            return pepProfile.pep_profile.associated_countries.map((country) => country.country_name);
          }
        }),
      ) || [];

    const genders =
      Array.from(
        new Set(
          pepCommandResult?.content?.output?.payload?.verified_pep_hits.map(
            (pepProfile) => pepProfile.gender || pepProfile.pep_profile.gender,
          ),
        ),
      ) || [];

    const locations = Array.from(new Set([...adverseMediaLocations, ...pepLocations]));

    setAvailableGenders(genders);
    setAvailableLocations(locations);
  }, [agentRun.status_messages]);

  const mapFunction = (step, type) => {
    if (step.command_type === "agent_group") {
      return [..._.flatMap(step.agent_config.steps, (stepArg) => mapFunction(stepArg, step.agent_config.agent_type))];
    }
    return { type, ...step };
  };

  const flatConfig = _.flatMap(agent.steps, (stepArg) => mapFunction(stepArg, agent.agent_type));
  const uniqueCheckIds = agentRun?.check_results?.map((check) => check.command_id);
  const checksGroupedByType = _.groupBy(flatConfig, (step) =>
    uniqueCheckIds?.includes(step.command_id) ? step.type : "other",
  );

  const allAgentsMap = useMemo(() => {
    const agentGroups = agent.steps.filter((step) => step.command_type === "agent_group");
    const agentGroupsByCommandIdMap = agentGroups.reduce((acc, step) => {
      return { ...acc, [step.command_id]: { ...step } };
    }, {});
    return {
      [agent.agent_key]: {
        agent_config: {
          agent_key: agent.agent_key,
          agent_name: agent.agent_name,
          agent_description: agent.agent_description,
          agent_type: agent.agent_type,
        },
      },
      ...agentGroupsByCommandIdMap,
    };
  }, [agent]);

  const initialFilteredMessages = agentRun?.status_messages?.filter(
    (message) =>
      !!message.content.agent_key &&
      !!message.content.agent_instance_id &&
      !!message.content.command_instance_id &&
      !message.content.is_agent_status &&
      ["api_results", "command_results", "status", "thought", "error"].includes(message.event) &&
      message.content?.command_id !== "finish_plan",
  );

  const groupedByType = _.chain(initialFilteredMessages)
    .filter((message) => !!message.content.agent_key)
    .groupBy((message) => {
      return allAgentsMap[message.content.agent_key]?.agent_config.agent_type;
    })
    .value();

  const groupedByAgentInstanceId = _.chain(groupedByType)
    .mapValues((group) => {
      return _.groupBy(group, (message) => message.content.agent_instance_id);
    })
    .value();

  const agentMessages = useMemo(() => {
    if (!allAgentsMap || !agentRun.status_messages || agentRun.status_messages?.length === 0) return;

    const mainAgentStatusMessages = agentRun.status_messages.filter(
      (message) => message.event === "status" && message.content.is_agent_status,
    );

    const filteredMessages = agentRun.status_messages.filter(
      (message) =>
        !!message.content.agent_instance_id &&
        !!message.content.command_instance_id &&
        !message.content.is_agent_status &&
        ["api_results", "command_results", "status", "thought", "error"].includes(message.event) &&
        message.content?.command_id !== "finish_plan",
    );
    const messageGroups = Object.entries(allAgentsMap).map(([k, v]) => {
      return {
        agentKey: k,
        agentName: v.agent_config.agent_name,
        agentType: v.agent_config.agent_type,
        messages: _.chain(filteredMessages)
          .filter((message) => message.content.agent_key === k)
          .groupBy((message) => message.content.agent_instance_id)
          .map((value, key) => ({
            id: key,
            agentName: (value[0].content as CommandResult).agent_name,
            agentKey: (value[0].content as CommandResult).agent_key,
            messages: value,
          }))
          .value(),
      };
    });
    const businessTabMessages = messageGroups.find((group) => group.agentType === "kyb")?.messages;
    const individualsTabMessages = messageGroups.find((group) => group.agentType === "kyc")?.messages;
    const associatedEntitiesTabMessages = messageGroups.find((group) => group.agentType === "entity")?.messages;

    const alertsTabMessages = _.chain(filteredMessages)
      .filter(
        (message) =>
          message.event === "command_results" &&
          !["true", "pass", "passed", "yes"].includes(message?.content?.output?.passed?.toString().toLowerCase()),
      )
      .groupBy((message) => message.content.agent_instance_id)
      .map((value, key) => ({
        id: key,
        agentName: (value[0].content as CommandResult).agent_name,
        agentKey: (value[0].content as CommandResult).agent_key,
        messages: value,
      }))
      .value();

    return {
      alertsTabMessages,
      businessTabMessages,
      filteredMessages,
      individualsTabMessages,
      mainAgentStatusMessages,
      associatedEntitiesTabMessages,
    };
  }, [agentRun.status_messages, allAgentsMap]);

  useEffect(() => {
    if (!agentMessages) return;

    const {
      alertsTabMessages,
      businessTabMessages,
      individualsTabMessages,
      mainAgentStatusMessages,
      associatedEntitiesTabMessages,
    } = agentMessages;
    setMainAgentStatusMessages(mainAgentStatusMessages);
    setAlertsMessages(alertsTabMessages);
    setBusinessMessages(businessTabMessages);
    setIndividualMessages(individualsTabMessages);
    setAssociatedEntitiesMessages(associatedEntitiesTabMessages);
  }, [agentMessages]);

  useEffect(() => {
    if (selectedTabId !== "") return;

    if (alertsMessages && Object.entries(alertsMessages).length) {
      setSelectedTabId("alerts");
    } else if (businessMessages && Object.entries(businessMessages).length) {
      setSelectedTabId("business");
    } else if (individualsMessages && Object.entries(individualsMessages).length) {
      setSelectedTabId("individuals");
    } else if (associatedEntitiesMessages && Object.entries(associatedEntitiesMessages).length) {
      setSelectedTabId("entities");
    }
  }, [alertsMessages, businessMessages, individualsMessages, associatedEntitiesMessages]);

  const fetchFeedbackData = () => {
    parchaApi
      .getFeedbackInputsForJob(contextFeedbackData?.feedbackData.endpoint.endpointUrl, agentRun.id)
      .then((res) => {
        setJobFeedbackData(res);
      });
  };

  const sendFeedbackHandler = (commandResult, isThumbsUp, comment) => {
    parchaApi
      .sendFeedback(
        contextFeedbackData?.feedbackData.endpoint.endpointUrl,
        agentRun.id,
        `${commandResult.content.agent_instance_id}${commandResult.content.command_instance_id}`,
        isThumbsUp,
        undefined,
        {
          feedback_key: "command_result",
          command_result: commandResult,
          endpoint: {
            agentKey: agent.agent_key,
            agentName: agent.agent_name,
            endpointUrl: agent.endpointUrl,
            default: true,
          },
          job_id: agentRun.id,
        },
        comment,
      )
      .then(async () => {
        await fetchFeedbackData();
      });
  };

  useEffect(() => {
    if (!agentRun) return;

    fetchFeedbackData();
  }, [agentRun.id]);

  const isFlutterwaveMCC = agentRun?.agent_key === "kyb-mcc-flutterwave";

  const onTabChangeHandler = (id) => {
    if (id === "business") {
      businessMessages.length > 0 ? setSelectedAgentInstanceId(businessMessages[0].id) : null;
    } else if (id === "individuals") {
      individualsMessages.length > 0 ? setSelectedAgentInstanceId(individualsMessages[0].id) : null;
    } else if (id === "entities") {
      associatedEntitiesMessages.length > 0 ? setSelectedAgentInstanceId(associatedEntitiesMessages[0].id) : null;
    }

    setSelectedTabId(id);
  };

  const onValueSelectionHandler = (id) => {
    setSelectedAgentInstanceId(id);
  };

  const onContactSupportClickHandler = (errorMsg: ErrorMsg | undefined) => {
    const errorMessage = agentRun.status_messages.find((message) => message.event === "error")?.content.error_display;
    const subject = `Error report in ${agentRun.agent_key} for job ${agentRun.job_id}`;
    const body = `There was an error job with ID ${agentRun.job_id} for ${agentRun.agent_key}.\n\n${errorMessage}`;
    const mailtoLink = `mailto:support@parcha.ai?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
      body || "",
    )}`;
    window.open(mailtoLink, "_blank");
  };

  const getIndividualName = (individualObject) => {
    if (!individualObject) return "";
    const name = `${individualObject.first_name ? individualObject.first_name : ""}${
      individualObject.middle_name ? ` ${individualObject.middle_name}` : ""
    }${individualObject.last_name ? ` ${individualObject.last_name}` : ""}`;

    return name;
  };

  const getBusinessName = (businessObject) => {
    if (!businessObject) return "";

    const businessName = businessObject.business_name || businessObject.registered_business_name;

    return businessName;
  };

  const getExtraTitle = (commandArgs, agentKey): string | null => {
    const agent = allAgentsMap[agentKey];

    if (agent && ["kyb", "entity"].includes(agent.agent_config.agent_type)) {
      return getBusinessName(commandArgs);
    } else if (agent && agent.agent_config.agent_type === "kyc") {
      const name = getIndividualName(commandArgs);
      const age = commandArgs.individual_age;
      const country =
        commandArgs.country_of_nationality?.country_name ??
        commandArgs.country_of_residence?.country_name ??
        commandArgs.address_country?.country_name ??
        null;
      return commandArgs ? toTitleCase(`${name}${age ? `, ${age}` : ""}${country ? ` - ${country}` : ""}`) : null;
    }
    return null;
  };

  const renderAlertTabContent = (groupedMessages) => {
    return (
      <>
        {groupedMessages.map((group) => {
          const groupedByCommandInstanceIdMessages = _.groupBy(
            group.messages,
            (message) => message.content.command_instance_id,
          );
          const firstMessage = group.messages[0];
          const extraTitleText = getAlertTitle(firstMessage, group.agentKey);

          return (
            <div key={group.id} className="flex flex-col gap-5">
              {extraTitleText ? <span className="font-medium">{extraTitleText}</span> : null}

              {Object.entries(groupedByCommandInstanceIdMessages).map(([commandInstanceId, messages]) => {
                let commandResult = messages.find((message) => message.event === "command_results");
                let auditLogMessages =
                  agentRun?.status_messages?.filter(
                    (message) =>
                      commandResult.content.command_instance_id === message.content.command_instance_id &&
                      commandResult.content.agent_instance_id === message.content.agent_instance_id &&
                      ["api_results", "command_results", "thought"].includes(message.event),
                  ) || [];

                return (
                  <div key={commandInstanceId} className="flex flex-col gap-y-5">
                    <CheckContainer
                      title={
                        Object.values(checksGroupedByType)
                          .flat()
                          .find((check) => check.command_id === commandResult.content.command_id)?.step_name
                      }
                      agent={agent}
                      agentRun={agentRun}
                      commandResult={commandResult}
                      endpointUrl={endpoint.endpointUrl}
                      feedbackData={jobFeedbackData}
                      sendFeedbackHandler={sendFeedbackHandler}
                      key={commandInstanceId}
                      messages={messages}
                      auditLogMessages={auditLogMessages}
                      isLegacyJob={isLegacyJob}
                      setSelectedSidePanelTabValue={setSelectedSidePanelTabValue}
                      showTabular={showTabular}
                      selectedGenders={selectedGenders}
                      selectedLocations={selectedLocations}
                    />
                  </div>
                );
              })}
            </div>
          );
        })}
      </>
    );
  };

  const renderTabContent = (messagesGroupedByAgentInstanceId, checks) => {
    if (selectedAgentInstanceId && messagesGroupedByAgentInstanceId[selectedAgentInstanceId]) {
      const messagesToRender = messagesGroupedByAgentInstanceId[selectedAgentInstanceId];

      const firstMessageWithArgs = messagesToRender?.find(
        (message) =>
          message.content.command_args && // Add this line to check if command_args is not null
          !message.content.command_args.input_data,
      );
      const extraTitleText = firstMessageWithArgs
        ? getExtraTitle(firstMessageWithArgs.content.command_args, firstMessageWithArgs.content.agent_key)
        : null;
      return (
        <div className="flex flex-col gap-5">
          {extraTitleText ? <span className="font-medium">{extraTitleText}</span> : null}
          {checks?.map((check) => {
            const matchingMessage = messagesToRender.find((message) => message.content.command_id === check.command_id);
            const messages = messagesToRender.filter((message) => {
              return (
                matchingMessage?.content.command_instance_id === message.content.command_instance_id &&
                matchingMessage?.content.agent_instance_id === message.content.agent_instance_id
              );
            });
            const commandResult = messages.find((message) => message.event === "command_results");
            const auditLogMessages = messages.filter((message) =>
              ["api_results", "command_results", "thought"].includes(message.event),
            );

            return (
              <div key={check.command_id}>
                <CheckContainer
                  agent={agent}
                  agentRun={agentRun}
                  endpointUrl={endpoint.endpointUrl}
                  feedbackData={jobFeedbackData}
                  sendFeedbackHandler={sendFeedbackHandler}
                  title={check.step_name}
                  key={check.command_id}
                  messages={messages}
                  commandResult={commandResult}
                  auditLogMessages={auditLogMessages}
                  isLegacyJob={isLegacyJob}
                  setSelectedSidePanelTabValue={setSelectedSidePanelTabValue}
                  showTabular={showTabular}
                  selectedGenders={selectedGenders}
                  selectedLocations={selectedLocations}
                />
              </div>
            );
          })}
        </div>
      );
    }

    return (
      <>
        {Object.entries(messagesGroupedByAgentInstanceId).map(([agentInstanceId, agentInstanceIdGroup]) => {
          const firstMessageWithArgs = agentInstanceIdGroup.find(
            (message) =>
              message.content.command_args && // Add this line to check if command_args is not null
              !message.content.command_args.input_data,
          );
          const extraTitleText = firstMessageWithArgs
            ? getExtraTitle(firstMessageWithArgs.content.command_args, firstMessageWithArgs.content.agent_key)
            : null;
          return (
            <div key={agentInstanceId} className="flex flex-col gap-5">
              {extraTitleText ? <span className="font-medium">{extraTitleText}</span> : null}
              {checks?.map((check) => {
                const matchingMessage = agentInstanceIdGroup.find(
                  (message) => message.content.command_id === check.command_id,
                );
                const messages = agentInstanceIdGroup.filter((message) => {
                  return (
                    matchingMessage?.content.command_instance_id === message.content.command_instance_id &&
                    matchingMessage?.content.agent_instance_id === message.content.agent_instance_id
                  );
                });
                const commandResult = messages.find((message) => message.event === "command_results");
                const auditLogMessages = messages.filter((message) =>
                  ["api_results", "command_results", "thought"].includes(message.event),
                );

                return (
                  <div key={check.command_id}>
                    <CheckContainer
                      agent={agent}
                      agentRun={agentRun}
                      endpointUrl={endpoint.endpointUrl}
                      feedbackData={jobFeedbackData}
                      sendFeedbackHandler={sendFeedbackHandler}
                      title={check.step_name}
                      key={check.command_id}
                      messages={messages}
                      commandResult={commandResult}
                      auditLogMessages={auditLogMessages}
                      isLegacyJob={isLegacyJob}
                      setSelectedSidePanelTabValue={setSelectedSidePanelTabValue}
                      showTabular={showTabular}
                      selectedGenders={selectedGenders}
                      selectedLocations={selectedLocations}
                    />
                  </div>
                );
              })}
            </div>
          );
        })}
      </>
    );
  };

  const getAlertTitle = (inputMessage, agentKey) => {
    const firstStatusMessage = agentMessages?.filteredMessages.find((message) => {
      return (
        (message.event === "status" || message.event === "api_results") &&
        message.content.agent_instance_id === inputMessage.content.agent_instance_id &&
        message.content.command_args != null // This line ensures command_args is not undefined or null
      );
    });

    if (!firstStatusMessage) return "";

    const agent = allAgentsMap[agentKey];
    if (agent && agent.agent_config.agent_type === "kyb") {
      return (
        firstStatusMessage.content.command_args.business_name ||
        firstStatusMessage.content.command_args.registered_business_name
      );
    } else if (agent && agent.agent_config.agent_type === "kyc") {
      const name = `${
        firstStatusMessage.content.command_args.first_name ? firstStatusMessage.content.command_args.first_name : ""
      } ${
        firstStatusMessage.content.command_args.middle_name ? firstStatusMessage.content.command_args.middle_name : ""
      } ${firstStatusMessage.content.command_args.last_name ? firstStatusMessage.content.command_args.last_name : ""}`;

      const individualName = name?.trim().length
        ? name
        : firstStatusMessage.content.command_args.name
          ? firstStatusMessage.content.command_args.name
          : "";

      const individualCountry =
        firstStatusMessage.content.command_args.country_of_nationality?.country_name ??
        firstStatusMessage.content.command_args.country_of_residence?.country_name ??
        firstStatusMessage.content.command_args.address_country?.country_name ??
        null;

      const individualAge = firstStatusMessage.content.command_args.individual_age;

      return toTitleCase(
        `Individual: ${individualName}${individualAge ? `, ${individualAge}` : ""}${
          individualCountry ? ` - ${individualCountry}` : ""
        }`,
      );
    } else if (agent && agent.agent_config.agent_type === "entity") {
      return `Entity: ${
        firstStatusMessage.content.command_args.business_name ||
        firstStatusMessage.content.command_args.registered_business_name ||
        ""
      }`;
    } else {
      const individualName = getIndividualName(firstStatusMessage.content.command_args);
      const businessName = getBusinessName(firstStatusMessage.content.command_args);
      return individualName ? `Individual: ${individualName}` : businessName ? `Entity: ${businessName}` : "";
    }

    return null;
  };

  const finalAnswerMessage = agentRun?.status_messages?.find((message) => message.event === "final_answer");
  const finalAnswerOutput = finalAnswerMessage?.content?.output as FinishPlanOutputSchema;

  const getFinalOutputTitle = () => {
    if (!agentRun) return "";

    if (!isFlutterwaveMCC) return agentRun.recommendation;

    const isHighRisk = agentRun.isHighRisk;

    if (isHighRisk) {
      return "High Risk";
    } else if (agentRun.recommendation === "unknown") {
      return "Unknown";
    }
    return agentRun.recommendation;
  };

  const getFinalOutputLevel = () => {
    if (!agentRun?.recommendation) return null;

    if (!isFlutterwaveMCC) {
      if (finalAnswerOutput?.recommendation === "Approve") return "success";
      if (finalAnswerOutput?.recommendation === "Deny") return "failure";
      if (finalAnswerOutput?.recommendation === "Review") return "caution";
      return "normal";
    }

    const finalAnswer = agentRun.status_messages?.find((statusMessage) => statusMessage.event === "final_answer");

    const isHighRisk =
      finalAnswer?.content?.output?.evidence?.some((ev) =>
        Boolean(ev.command_result?.output?.alerts?.high_risk_mcc_alert),
      ) || false;
    const inputMccCode = agentRun.input_payload?.self_attested_data.mcc_code;
    const recommendationMccCode = agentRun.recommendation;

    if (isHighRisk || recommendationMccCode === "unknown") {
      return "failure";
    } else if (recommendationMccCode === "unknown") {
      return "failure";
    } else if (inputMccCode && recommendationMccCode && inputMccCode === recommendationMccCode) {
      return "success";
    } else if (
      (inputMccCode && recommendationMccCode && inputMccCode !== recommendationMccCode) ||
      recommendationMccCode === "Review"
    ) {
      return "caution";
    }
    return "normal";
  };

  const businessEntries = businessMessages ? Object.entries(businessMessages) : [];
  const alertEntries = alertsMessages ? Object.entries(alertsMessages) : [];
  const individualsEntries = individualsMessages ? Object.entries(individualsMessages) : [];
  const associatedEntitiesEntries = associatedEntitiesMessages ? Object.entries(associatedEntitiesMessages) : [];
  const mostRecentStatusMessage = mainAgentStatusMessages[mainAgentStatusMessages.length - 1];

  const isThereWebPresenceCheck = jobMetadata?.some((check) => check.command_id === "kyb.web_presence_check") ?? false;

  return (
    <div className="h-full flex flex-col justify-center items-center">
      <div className="flex flex-col w-full h-full">
        <div className="flex overflow-y-auto h-[calc(100vh-6rem)]">
          <div className="flex flex-col grow gap-y-5 w-full h-full">
            {agentRun.status === "in progress" && mostRecentStatusMessage ? (
              <StatusUI messageContents={{ status: mostRecentStatusMessage.content.status || "" }} />
            ) : null}
            {finalAnswerOutput && (
              <FinalOutput
                level={getFinalOutputLevel()}
                message={
                  <div className="flex flex-col gap-y-2">
                    <h2 className="font-semibold">{getFinalOutputTitle()}</h2>
                    <div>{finalAnswerOutput.answer}</div>
                  </div>
                }
                feedbackContent={finalAnswerMessage}
              />
            )}
            <div className="flex justify-center items-center gap-x-2 px-2">
              <div className="flex-1">
                {alertEntries.length ||
                businessEntries.length ||
                individualsEntries.length ||
                associatedEntitiesEntries.length ? (
                  <div className="flex items-center gap-x-4">
                    <div className="flex-1">
                      <FullWidthTabs
                        tabs={TABS.map((tab) => {
                          if (!isThereWebPresenceCheck && tab.id === "findings") {
                            return null;
                          }

                          switch (tab.id) {
                            case "findings": {
                              return { ...tab, count: 0 };
                            }
                            case "alerts": {
                              if (!alertEntries.length) return null;

                              return {
                                ...tab,
                                count: alertsMessages
                                  ? Object.values(alertsMessages).flatMap((entry) => entry.messages).length
                                  : 0,
                              };
                            }
                            case "business": {
                              if (!businessEntries.length) return null;

                              return { ...tab, count: businessEntries.length };
                            }
                            case "individuals": {
                              if (!individualsEntries.length) return null;

                              const values = individualsMessages.map((commandInstance, index) => {
                                const messageWithCommandArgs = commandInstance.messages.find((message) =>
                                  Boolean(message.content.command_args),
                                );
                                return messageWithCommandArgs
                                  ? {
                                      label: getIndividualName(messageWithCommandArgs.content.command_args),
                                      value: commandInstance.id,
                                    }
                                  : `Individual ${index + 1}`;
                              });

                              return { ...tab, count: individualsEntries.length, values };
                            }
                            case "entities": {
                              if (!associatedEntitiesEntries.length) return null;

                              const values = associatedEntitiesMessages.map((commandInstance, index) => {
                                const messageWithCommandArgs = commandInstance.messages.find((message) =>
                                  Boolean(message.content.command_args),
                                );
                                return messageWithCommandArgs
                                  ? {
                                      label: getBusinessName(messageWithCommandArgs.content.command_args),
                                      value: commandInstance.id,
                                    }
                                  : `Entity ${index + 1}`;
                              });

                              return { ...tab, count: associatedEntitiesEntries.length, values };
                            }
                          }
                        }).filter((entry) => Boolean(entry))}
                        selectedTab={selectedTabId}
                        onTabChangeHandler={onTabChangeHandler}
                        onValueSelectionHandler={onValueSelectionHandler}
                        enableValueSelector={agentRun?.status === "complete"}
                      />
                    </div>
                    {/* <div className="px-5 text-xs flex justify-end items-center gap-x-1">
                      <span className="w-[5rem] font-semibold">View Format:</span>
                      <span className="isolate inline-flex rounded-md shadow-sm">
                        <button
                          onClick={() => setShowTabular(false)}
                          type="button"
                          className={twJoin(
                            "relative inline-flex items-center rounded-l-md p-1 ring-1 ring-inset ring-slate-300 focus:z-10",
                            !showTabular ? "text-white bg-slate-900" : "text-slate-900 bg-white",
                          )}
                        >
                          <span className="sr-only">Card View</span>
                          <ViewAgendaOutlined sx={{ fontSize: "1rem" }} aria-hidden="true" className="h-4 w-4" />
                        </button>
                        <button
                          onClick={() => setShowTabular(true)}
                          type="button"
                          className={twJoin(
                            "relative -ml-px inline-flex items-center rounded-r-md p-1 ring-1 ring-inset ring-slate-300 focus:z-10",
                            showTabular ? "text-white bg-slate-900" : "text-slate-900 bg-white",
                          )}
                        >
                          <span className="sr-only">Tabular View</span>
                          <ViewHeadlineOutlined sx={{ fontSize: "1rem" }} aria-hidden="true" />
                        </button>
                      </span>
                    </div> */}
                  </div>
                ) : null}
              </div>
            </div>
            {(["queued", "error"].includes(agentRun.status) ||
              (agentRun.status === "in progress" && !mostRecentStatusMessage)) && (
              <div className="h-full">
                {agentRun.status === "queued" ? (
                  <div className="flex flex-col justify-center items-center w-full h-full text-brand-purple text-sm gap-y-2">
                    <HourglassTopRounded sx={{ fontSize: "2rem" }} className="text-slate-500" />
                    <div className="text-center">
                      <p className="font-bold">This job is queued up</p>
                      <p>It will start executing once the current in progress jobs are completed.</p>
                    </div>
                  </div>
                ) : agentRun.status === "in progress" && !mostRecentStatusMessage ? (
                  <div className="flex flex-col justify-center items-center w-full h-full text-brand-purple text-sm gap-y-2">
                    <Box
                      className={styles.loader}
                      component="img"
                      src={"/working_loader.svg"}
                      alt="logo"
                      width={32}
                      sx={{
                        padding: "0",
                        animationName: "spin",
                        animationTimingFunction: "linear",
                        animationIterationCount: "infinite",
                        animationDuration: "3s",
                        animationDelay: "0",
                        "@keyframes spin": {
                          "0%": { transform: "rotate(0deg)" },
                          "100%": { transform: "rotate(360deg)" },
                        },
                      }}
                    />
                    <div className="text-center">
                      <p className="font-bold">This job is in progress</p>
                      <p>The checks should show up soon...</p>
                    </div>
                  </div>
                ) : ["error"].includes(agentRun.status) ? (
                  <div className="flex justify-center px-0 md:px-5 lg:px-20">
                    <div className="mt-20 flex flex-col justify-center items-center w-full p-2 md:p-5 lg:p-10 text-sm gap-y-2 bg-slate-50 rounded-[1.25rem]">
                      <ErrorRounded sx={{ fontSize: "2rem" }} className="text-red-600" />
                      <div className="text-center">
                        <p className="text-xs lg:text-sm font-semibold lg:font-bold text-slate-700">
                          An error has occurred
                        </p>
                        <p className="text-slate-500 text-xs">There was an issue loading information for</p>
                        <p className="text-slate-500 font-bold text-xs">
                          {agentRun.input_payload?.self_attested_data?.name ||
                            agentRun.input_payload?.self_attested_data?.business_name ||
                            agentRun.input_payload?.self_attested_data?.registered_business_name ||
                            `${agentRun.input_payload?.self_attested_data?.first_name || ""}${
                              agentRun.input_payload?.self_attested_data?.middle_name
                                ? ` ${agentRun.input_payload.self_attested_data.middle_name}`
                                : ""
                            }${
                              agentRun.input_payload?.self_attested_data?.last_name
                                ? ` ${agentRun.input_payload.self_attested_data.last_name}`
                                : ""
                            }`}
                        </p>
                        <button
                          type="button"
                          className="mt-4 text-sm relative inline-flex justify-center items-center rounded-md bg-brand-purple p-2 h-4 border border-solid border-brand-purple text-white focus:z-10 "
                          onClick={onRetryJobClick}
                        >
                          <ReplayCircleFilled sx={{ fontSize: "1rem" }} className="mr-1 h-4 w-4" aria-hidden="true" />
                          <span className="line-clamp-1 text-xs">Run this job again</span>
                        </button>
                        <p className="mt-4 text-xs text-slate-500">
                          If the problem persists please contact support and we will look into the issue as soon as
                          possible.
                        </p>
                        <button
                          type="button"
                          className="mt-4 text-sm relative inline-flex justify-center items-center rounded-md bg-white border border-solid border-slate-300 p-2 h-4 text-slate-900 focus:z-10 "
                          onClick={onContactSupportClickHandler}
                        >
                          <EmailRounded sx={{ fontSize: "1rem" }} className="mr-1 h-4 w-4" aria-hidden="true" />
                          <span className="line-clamp-1 text-xs">Contact Support</span>
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            )}
            {groupedByAgentInstanceId && Object.keys(groupedByAgentInstanceId).length > 0 && (
              <div className="flex flex-col gap-5 py-4 px-8 h-full">
                {selectedTabId === "alerts" && renderAlertTabContent(alertsMessages)}
                {selectedTabId === "findings" && (
                  <Findings
                    jobMetadata={jobMetadata?.filter((checkResult) => checkResult.case_type === "business")}
                    endpointUrl={endpoint.endpointUrl}
                    jobId={agentRun.id}
                    onTryAgainClick={onTryAgainClick}
                  />
                )}
                {selectedTabId === "business" &&
                  groupedByAgentInstanceId &&
                  groupedByAgentInstanceId["kyb"] &&
                  renderTabContent(groupedByAgentInstanceId["kyb"], checksGroupedByType["kyb"])}
                {selectedTabId === "individuals" &&
                  groupedByAgentInstanceId &&
                  groupedByAgentInstanceId["kyc"] &&
                  renderTabContent(groupedByAgentInstanceId["kyc"], checksGroupedByType["kyc"])}
                {selectedTabId === "entities" &&
                  groupedByAgentInstanceId &&
                  groupedByAgentInstanceId["entity"] &&
                  (isThereWebPresenceCheck ? (
                    <AssociatedEntityFindings
                      selectedAgentInstanceId={selectedAgentInstanceId}
                      jobMetadata={jobMetadata?.filter(
                        (checkResult) =>
                          checkResult.agent_instance_id.replaceAll("-", "") ===
                          selectedAgentInstanceId?.replaceAll("-", ""),
                      )}
                      jobId={agentRun.id}
                      endpointUrl={endpoint.endpointUrl}
                    />
                  ) : (
                    renderTabContent(groupedByAgentInstanceId["entity"], checksGroupedByType["entity"])
                  ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentRunChecksView;
