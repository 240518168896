import instragramLogo from "../../assets/instagram_logo.png";

type InstagramPost = {
  id: string;
  displayUrl: string | null;
  url: string | null;
};

type InstagramProfileCardProps = {
  biography: string | null;
  businessCategoryName: string | null;
  logoUrl: string | null;
  name: string | null;
  followers: number | null;
  instagramUrl: string | null;
  externalUrl: string | null;
  latestPosts?: InstagramPost[] | null;
};

const InstagramProfileCard = ({
  biography,
  businessCategoryName,
  logoUrl,
  name,
  followers,
  instagramUrl,
  externalUrl,
  latestPosts,
}: InstagramProfileCardProps) => {
  if (!name && !instagramUrl) return null;

  const availablePosts = latestPosts?.filter((post) => {
    return !post.displayUrl?.includes("instagram");
  });

  return (
    <div className="w-full rounded-xl text-slate-900 text-xs p-4 bg-slate-50 overflow-hidden">
      <div className="flex flex-col gap-y-5">
        <div className="flex flex-col gap-y-4">
          <div className="flex items-center justify-between gap-x-2">
            <div className="flex items-center gap-x-2">
              <img src={instragramLogo} alt="Instagram Logo" className="w-4 h-4" />
              <span className="font-semibold">Instagram</span>
            </div>
            <div className="h-6.5 min-w-[6rem] flex justify-center items-center rounded-full bg-slate-50 border border-[#334155]/20 py-1 px-2 text-slate-700">
              Social Media
            </div>
          </div>
          {instagramUrl && (
            <a href={instagramUrl} target="_blank" rel="noreferrer" className="w-fit text-brand-purple hover:underline">
              {instagramUrl}
            </a>
          )}
          <div className="flex gap-x-3">
            {logoUrl && !logoUrl.includes("https://instagram.") && (
              <img
                src={logoUrl}
                alt="Company Logo"
                className="w-12 h-12 rounded-lg object-cover"
                onError={({ currentTarget }) => (currentTarget.style.display = "none")}
              />
            )}
            <div className="flex flex-col gap-y-0.5">
              <div className="flex flex-wrap items-baseline gap-x-1">
                <span className="text-base text-bold">{name}</span>
                {externalUrl && <span className="truncate text-slate-500">{externalUrl}</span>}
              </div>
              {businessCategoryName && <div className="text-slate-500">{businessCategoryName}</div>}
              {followers && <div className="text-slate-500">{followers} Followers</div>}
            </div>
          </div>
          {biography && (
            <div className="flex flex-col gap-y-1">
              <span className="font-semibold">Biography</span>
              <p className="text-slate-700">{biography}</p>
            </div>
          )}
          {availablePosts && (
            <div className="grid grid-cols-3 gap-1">
              {availablePosts.slice(0, 3).map((post) => {
                if (!post.url || !post.displayUrl) return null;

                return (
                  <a href={post.url} key={post.id} target="_blank" rel="noreferrer">
                    <img src={post.displayUrl} className="object-cover aspect-square" />
                  </a>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InstagramProfileCard;
