// @ts-nocheck
import AddressesFindings from "@/components/TailwindComponents/AddressesFindings";
import BusinessBasicProfile from "@/components/TailwindComponents/BusinessBasicProfile";
import BusinessOwnersFindings from "@/components/TailwindComponents/BusinessOwnersFindings";
import WebPresenceList from "@/components/TailwindComponents/WebPresence";
import MCCFindings from "@/components/TailwindComponents/MCCFindings";
import PoliciesFindings from "@/components/TailwindComponents/PoliciesFindings";
import HighRiskCountryFindings from "@/components/TailwindComponents/HighRiskCountryFindings";
import HighRiskIndustryFindings from "@/components/TailwindComponents/HighRiskIndustryFindings";

import { useParchaApi } from "@/hooks/useParchaApi";
import { useEffect, useState } from "react";
import { VerifiedUserRounded } from "@mui/icons-material";
import ParchaLoadingScreen from "../ParchaLoadingScreen";
import AdverseMediaFindings from "@/components/TailwindComponents/AdverseMediaFindings";
import SanctionsWatchlistFindings from "@/components/TailwindComponents/SanctionsWatchlistFindings";
import FindingsLoadingIndicator from "@/components/TailwindComponents/FindingsLoadingIndicator";

const BUSINESS_PROFILE_CHECK_ID = "kyb.basic_business_profile_check";
const WEB_PRESENCE_CHECK_ID = "kyb.web_presence_check";
const BUSNESS_OWNERS_CHECK_ID = "kyb.business_owners_check";
const ADDRESS_CHECK_ID = "kyb.addresses_check";
const POLICY_CHECK_ID = "kyb.policy_check";
const HIGH_RISK_COUNTRY_CHECK_ID = "kyb.high_risk_country_tool";
const HIGH_RISK_INDUSTRY_CHECK_ID = "kyb.high_risk_industry_tool";
const MCC_CHECK_ID = "kyb.mcc_code_check.mcc_code_check_tool";
const ADVERSE_MEDIA_CHECK_ID = "kyb.screening.adverse_media_tool";
const SANCTIONS_WATCHLIST_CHECK_ID = "kyb.sanctions_watchlist_check";

const Findings = ({ jobMetadata, jobId, endpointUrl, onTryAgainClick }) => {
  const parchaApi = useParchaApi();
  const [isLoading, setIsLoading] = useState(true);
  const [results, setResults] = useState([]);

  useEffect(() => {
    const fetchCheckResults = async () => {
      const results = await Promise.all(
        commandIds.map(async (command) => {
          const result = await parchaApi.getCheckResultFromJob(
            endpointUrl,
            jobId,
            command.checkId,
            command.agentInstanceId,
            "kyb",
          );
          return { ...result, checkId: command.checkId };
        }),
      );
      return results;
    };
    const commandIds = jobMetadata?.map((entry) => ({
      checkId: entry.command_id,
      agentInstanceId: entry.agent_instance_id,
    }));

    fetchCheckResults().then((res) => {
      setResults(res);
      setIsLoading(false);
    });
  }, []);

  const businessProfileCheckResult = results.find((result) => result.checkId === BUSINESS_PROFILE_CHECK_ID);
  const webPresenceCheckResult = results.find((result) => result.checkId === WEB_PRESENCE_CHECK_ID);
  const policiesCheckResult = results.find((result) => result.checkId === POLICY_CHECK_ID);
  const addressesCheckResult = results.find((result) => result.checkId === ADDRESS_CHECK_ID);
  const highRiskCountryCheckResult = results.find((result) => result.checkId === HIGH_RISK_COUNTRY_CHECK_ID);
  const highRiskIndustryCheckResult = results.find((result) => result.checkId === HIGH_RISK_INDUSTRY_CHECK_ID);
  const mccCheckResult = results.find((result) => result.checkId === MCC_CHECK_ID);
  const businessOwnersCheckResult = results.find((result) => result.checkId === BUSNESS_OWNERS_CHECK_ID);
  const adverseMediaCheckResult = results.find((result) => result.checkId === ADVERSE_MEDIA_CHECK_ID);
  const sanctionsWatchlistCheckResult = results.find((result) => result.checkId === SANCTIONS_WATCHLIST_CHECK_ID);

  const sites =
    (webPresenceCheckResult?.verified_data?.self_attested_webpages?.length > 0
      ? webPresenceCheckResult?.verified_data?.self_attested_webpages
      : webPresenceCheckResult?.verified_data?.google_search_results) || [];
  let officialBusinessWebsite =
    sites?.find(
      (website) =>
        website.webpage_metadata?.webpage_type === "official_business_website" ||
        website.analysis?.extracted_metadata?.webpage_type === "official_business_website",
    ) || webPresenceCheckResult?.verified_data?.self_attested_webpages?.[0];

  if (officialBusinessWebsite) {
    officialBusinessWebsite["title"] =
      officialBusinessWebsite.webpage_title || officialBusinessWebsite.scanned_website?.webpage_title;
    officialBusinessWebsite["visual_summary"] =
      officialBusinessWebsite.webpage_metadata?.visual_summary ||
      officialBusinessWebsite.analysis?.extracted_metadata?.visual_summary ||
      officialBusinessWebsite.webpage_metadata?.summary ||
      officialBusinessWebsite.scanned_website?.webpage_metadata?.summary;
    officialBusinessWebsite["url"] =
      officialBusinessWebsite.webpage_url || officialBusinessWebsite.scanned_website?.webpage_url;
  } else {
    officialBusinessWebsite =
      webPresenceCheckResult?.check_result?.official_website_matches ||
      webPresenceCheckResult?.check_result?.official_website_match;
  }

  if (
    officialBusinessWebsite &&
    !officialBusinessWebsite.screenshot_url &&
    !officialBusinessWebsite.scanned_website?.screenshot_url
  ) {
    // use first self attested result (backwards compatibility)
    officialBusinessWebsite.screenshot_url =
      webPresenceCheckResult?.verified_data?.self_attested_webpages?.find(
        (item) =>
          item.webpage_url === officialBusinessWebsite?.url ||
          item.scanned_website?.webpage_url === officialBusinessWebsite?.url,
      )?.screenshot_url ||
      webPresenceCheckResult?.verified_data?.self_attested_webpages?.find(
        (item) =>
          item.webpage_url === officialBusinessWebsite?.url ||
          item.scanned_website?.webpage_url === officialBusinessWebsite?.url,
      )?.webpage_structured_scrape?.profilePicUrlHD;
  }

  return (
    <div className="flex justify-center align-center h-full w-full">
      {isLoading ? (
        <ParchaLoadingScreen message="Loading job findings... see real time updates in the Business tab." />
      ) : (
        <div className="flex flex-col gap-y-5 w-full">
          {businessProfileCheckResult && (
            <div className="flex-1 last:pb-[5rem]">
              <BusinessBasicProfile
                checkResult={businessProfileCheckResult}
                officialBusinessWebsite={
                  officialBusinessWebsite && {
                    webpage_url: officialBusinessWebsite.url || officialBusinessWebsite.scanned_website?.url,
                    screenshot_url:
                      officialBusinessWebsite.screenshot_url || officialBusinessWebsite.scanned_website?.screenshot_url,
                    title: officialBusinessWebsite.title || officialBusinessWebsite.scanned_website?.title,
                    visual_summary:
                      officialBusinessWebsite.visual_summary || officialBusinessWebsite.scanned_website?.visual_summary,
                  }
                }
                onTryAgainClick={onTryAgainClick}
              />
            </div>
          )}

          {webPresenceCheckResult && (
            <div className="flex-1 last:pb-[5rem]">
              <WebPresenceList checkResult={webPresenceCheckResult} />
            </div>
          )}

          {policiesCheckResult && (
            <div className="flex-1 last:pb-[5rem]">
              <PoliciesFindings checkResult={policiesCheckResult} endpointUrl={endpointUrl} />
            </div>
          )}

          {addressesCheckResult && (
            <div className="flex-1 last:pb-[5rem]">
              <AddressesFindings checkResult={addressesCheckResult} />
            </div>
          )}

          {(highRiskCountryCheckResult || highRiskIndustryCheckResult) && (
            <div className="flex-1 flex gap-x-5 justify-between last:pb-[5rem]">
              {highRiskCountryCheckResult && (
                <div className="flex-1">
                  <HighRiskCountryFindings checkResult={highRiskCountryCheckResult} />
                </div>
              )}
              {highRiskIndustryCheckResult && (
                <div className="flex-1">
                  <HighRiskIndustryFindings checkResult={highRiskIndustryCheckResult} />
                </div>
              )}
            </div>
          )}

          {mccCheckResult?.check_result && (
            <div className="flex-1 last:pb-[5rem]">
              <MCCFindings checkResult={mccCheckResult} />
            </div>
          )}

          {(adverseMediaCheckResult || sanctionsWatchlistCheckResult) && (
            <div className="flex-1 flex flex-col gap-y-3 last:pb-[5rem]">
              <div className="flex items-center gap-x-2">
                <VerifiedUserRounded sx={{ fontSize: "1.25rem" }} className="text-slate-700" />
                <span className="text-lg font-semibold">Business Screenings</span>
              </div>
              <div className="grid grid-cols-2 gap-3">
                {adverseMediaCheckResult && (
                  <div className="flex-1">
                    <AdverseMediaFindings checkResult={adverseMediaCheckResult} />
                  </div>
                )}
                {sanctionsWatchlistCheckResult && (
                  <div>
                    <SanctionsWatchlistFindings checkResult={sanctionsWatchlistCheckResult} />
                  </div>
                )}
              </div>
            </div>
          )}

          {businessOwnersCheckResult?.check_result && (
            <div className="flex-1 last:pb-[5rem]">
              <BusinessOwnersFindings checkResult={businessOwnersCheckResult} endpointUrl={endpointUrl} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default Findings;
