import { ReactNode } from "react";

const DetailValue = ({ value, icon }: { value: string; icon: ReactNode }) => {
  return (
    <div className="flex items-center">
      <div className="mr-2 text-slate-500">{icon}</div>
      <div className="text-slate-700">{value}</div>
    </div>
  );
};

export default DetailValue;
