// @ts-nocheck
import { Fragment, useCallback, useContext, useEffect, useRef, useState } from "react";
import _ from "lodash";
import useAuth from "@parcha/hooks/useAuth";
import { Button, Box, TextField } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import Papa from "papaparse";
import { Dialog, Transition } from "@headlessui/react";
import { useNavigate, useParams } from "react-router-dom";
import COUNTRIES from "@/data/countries";
import { ErrorContext } from "../../contexts/ErrorContext";
import { Endpoint } from "../../types";
import Queue from "./Queue";
import { Close, PlayCircleFilledRounded, ViewListRounded } from "@mui/icons-material";
import { useDropzone } from "react-dropzone";
import { useParchaApi } from "@/hooks/useParchaApi";
import { AntTabList, AntTab } from "../TailwindComponents/NewAgentChatSSE";
import SelfServeForm from "../SelfServeForm";
import InputWithOverlappingLabel from "../TailwindComponents/InputWithOverlappingLabel";

export const V2HomePage = () => {
  const [isRunAgentCaseModalOpen, setIsRunAgentCaseModalOpen] = useState<boolean>(false);
  const [isAddCaseModalOpen, setIsAddCaseModalOpen] = useState<boolean>(false);
  const { isLoading, isAuthenticated, user, endpoints, tenantName } = useAuth();

  const parchaApi = useParchaApi();
  const [agent, setAgent] = useState<Agent>();
  const [selectedEndpoint, setSelectedEndpoint] = useState<Endpoint>();
  const [tabsValue, setTabsValue] = useState("0");
  const [fileMessage, setFileMessage] = useState("");
  const [acceptedFile, setAcceptedFile] = useState<File | null>(null);
  const [bulkRunCount, setBulkRunCount] = useState<number>(0);
  const [isSubmittingCaseInfoForm, setIsSubmittingCaseInfoForm] = useState<boolean>(false);
  const { routeEndpoint, routeJobId } = useParams(); // get the url parameters
  const navigate = useNavigate(); // get the navigate function
  const { setError } = useContext(ErrorContext);
  const businessNameFieldRef = useRef();

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];

    if (file && file.type === "text/csv") {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          setBulkRunCount(results.data.length);
        },
      });
      setFileMessage(`File accepted: ${file.name}`);
      setAcceptedFile(file); // Store the accepted file in the state
    } else {
      setFileMessage("Only CSV files are allowed");
      setAcceptedFile(null); // Reset the accepted file state if not a CSV
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  if (!isLoading && !isAuthenticated) {
    navigate("/signin");
  }

  const handleApiException = (error: Error) => {
    setError(error);
  };

  useEffect(() => {
    if (!selectedEndpoint) return;

    parchaApi
      .getSimpleAgent(selectedEndpoint.endpointUrl, selectedEndpoint.agentKey)
      .then((a: Agent) => {
        setAgent(a);
      })
      .catch(handleApiException);
  }, [selectedEndpoint]);

  useEffect(() => {
    if (!isAddCaseModalOpen) {
      setTabsValue("0");
      setAcceptedFile(null);
      setFileMessage(null);
      setIsSubmittingCaseInfoForm(false);
    }
  }, [isAddCaseModalOpen]);

  useEffect(() => {
    if (isLoading || !endpoints) return;

    if (endpoints.length && routeEndpoint) {
      const endpoint = endpoints?.find((ep) => ep.agentKey === routeEndpoint);
      if (endpoint) {
        setSelectedEndpoint(endpoint);
      } else {
        setError(new Error(`No endpoint found with agentKey`));
      }
    } else if (endpoints.length) {
      navigate(`/jobs/${endpoints[0].agentKey}`, { replace: true });
    } else {
      setError(new Error("No endpoints configured for this user"));
    }
  }, [isLoading, endpoints, routeEndpoint]);

  const handleJobRunSubmit = (event) => {
    event.preventDefault();
    setIsSubmittingCaseInfoForm(true);
    const target = event.target as typeof event.target & {
      business_name: { value: string };
      registered_business_name: { value: string };
      business_purpose: { value: string };
      website: { value: string };
      mcc: { value: string | undefined };
      country_of_operation: { value: string };
    };

    const inputData = {
      id: target.business_name.value.toLowerCase().replaceAll(" ", "_"),
      self_attested_data: {
        address_of_operation: {
          country_code: target.country_of_operation?.value ?? undefined,
        },
        business_name: target.business_name.value,
        business_purpose: target.business_purpose.value,
        mcc_code: target.mcc?.value ?? undefined,
        registered_business_name: target.registered_business_name?.value ?? undefined,
        website: target.website.value,
      },
    };

    const agentJobInput: AgentJobInput = {
      agent_key: selectedEndpoint.agentKey,
      kyb_schema: inputData as InputPayload,
    };

    parchaApi
      .enqueueAgent(selectedEndpoint.endpointUrl, agentJobInput)
      .then((res) => {
        const jobId = res.job_id;
        window.location.href = `/jobs/${selectedEndpoint?.agentKey}/${jobId}`;
      })
      .catch((err) => setError(err))
      .finally(() => {
        setIsAddCaseModalOpen(false);
        setIsSubmittingCaseInfoForm(false);
      });
  };

  const handleTabsChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabsValue(newValue);
  };

  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });
    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const handleDownloadCSVTemplateClick = (e) => {
    e.preventDefault();
    let headers = ["business_name,website,business_purpose,mcc_code"];

    downloadFile({
      data: headers,
      fileName: "parcha_bulk_case_upload_template.csv",
      fileType: "text/csv",
    });
  };

  const handleCSVJobSRunSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (selectedEndpoint && acceptedFile) {
      setIsSubmittingCaseInfoForm(true);
      parchaApi
        .enqueueFromCSV(selectedEndpoint.endpointUrl, acceptedFile as File, selectedEndpoint.agentKey)
        .then((res) => {
          location.href = `/jobs/${selectedEndpoint.agentKey}`;
        })
        .catch((err) => setError(err))
        .finally(() => {
          setIsSubmittingCaseInfoForm(false);
        });
    }
  };

  function a11yProps(index: number) {
    return {
      id: `run-case-tab-${index}`,
      "aria-controls": `run-case-tabpanel-${index}`,
    };
  }

  return (
    <>
      <main className="w-full h-full">
        {/* * NAV START */}
        {/* <div className="h-full flex flex-col">
          <div className="bg-brand-purple text-white" id="logo">
            <Link to="/beta">
              <ParchaLogo />
            </Link>
          </div>
          <div className="w-full h-full py-5 bg-slate-900 text-white text-[1.5rem] flex flex-col justify-between">
            <nav className="flex-grow-1 flex flex-col items-center">
              <ul role="navigation" className="flex flex-col items-center w-12 h-12 gap-4 text-slate-400">
                <li className="hover:bg-slate-800 hover:text-white w-full flex justify-center p-3 text-white">
                  <Link to="/beta">
                    <FactCheckIcon fontSize="inherit" />
                  </Link>
                </li>
              </ul>
            </nav>
            <div className="w-full flex justify-center">
              <Link to="/signout" className="p-3 text-slate-400 hover:bg-slate-800 hover:text-white">
                <LogoutIcon className="text-center" fontSize="inherit" />
              </Link>
            </div>
          </div>
        </div> */}
        {/** NAV ENDS */}
        {!isLoading && endpoints?.length && selectedEndpoint && routeEndpoint ? (
          <Queue
            agent={agent}
            endpoints={endpoints}
            title={selectedEndpoint.agentName}
            selectedEndpoint={selectedEndpoint}
            setIsAddCaseModalOpen={setIsAddCaseModalOpen}
            setIsRunAgentCaseModalOpen={setIsRunAgentCaseModalOpen}
            user={user}
          />
        ) : null}
      </main>
      <Transition.Root show={isAddCaseModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-20" onClose={setIsAddCaseModalOpen} initialFocus={businessNameFieldRef}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-20 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-full sm:w-2/5">
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      className="rounded bg-white text-slate-700 hover:text-slate-900 p-.5 focus:outline-none focus:ring-1 focus:ring-brand-purple focus:ring-offset-1"
                      onClick={() => setIsAddCaseModalOpen(false)}
                    >
                      <span className="sr-only">Close</span>
                      <Close sx={{ fontSize: "1rem" }} className="h-2 w-2" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="mt-5 text-sm">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-slate-900 px-5 my-5">
                      Enter a case to run
                    </Dialog.Title>
                    <TabContext value={tabsValue}>
                      <>
                        <AntTabList
                          sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                          className="text-sm"
                          value={tabsValue}
                          aria-label="run case method selector"
                          onChange={handleTabsChange}
                        >
                          <AntTab sx={{ flex: "1 1 100%" }} label="Case Information" {...a11yProps(0)} value="0" />
                          <AntTab sx={{ flex: "1 1 100%" }} label="Bulk Upload Cases" {...a11yProps(1)} value="1" />
                        </AntTabList>
                      </>

                      <TabPanel value="0" sx={{ width: "100%" }}>
                        <form
                          onSubmit={handleJobRunSubmit}
                          autoComplete="off"
                          className="w-full mt-4 flex flex-col gap-y-5"
                        >
                          <div className="flex gap-x-2 gap-y-6 w-full">
                            <InputWithOverlappingLabel
                              required
                              fieldName="business_name"
                              label="Business Name"
                              id="business-name"
                              name="business_name"
                              className="grow"
                            />
                          </div>

                          <div className="grid grid-cols-6 gap-x-3">
                            <InputWithOverlappingLabel
                              required
                              fieldName="website"
                              label="Website URL"
                              id="website"
                              name="website"
                              className="col-span-3"
                            />

                            <InputWithOverlappingLabel
                              fieldName="mcc"
                              label="MCC Code"
                              id="mcc"
                              name="mcc"
                              className={"col-span-3"}
                            />
                          </div>

                          <div className="relative">
                            <label
                              htmlFor="businessPurpose"
                              className="absolute -top-2 left-2 bg-white px-1.5 block text-xs text-slate-900"
                            >
                              Business Description
                            </label>
                            <textarea
                              rows={4}
                              name="business_purpose"
                              id="business-purpose"
                              className="box-border block w-full rounded-md border-0 py-1.5 text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-brand-purple text-xs sm:leading-6"
                            />
                          </div>

                          <div className="flex items-center justify-between">
                            <button
                              type="submit"
                              className="place-self-end w-fit inline-flex justify-center items-center py-2 px-4 border border-transparent shadow-sm rounded-md text-white bg-green-600 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600"
                            >
                              <PlayCircleFilledRounded
                                sx={{ fontSize: "1rem" }}
                                className="mr-1 h-4 w-4"
                                aria-hidden="true"
                              />
                              <span className="line-clamp-1">Run Case</span>
                            </button>
                          </div>
                        </form>
                      </TabPanel>
                      <TabPanel value="1" sx={{ width: "100%" }}>
                        <Box
                          {...getRootProps()}
                          sx={{ backgroundColor: "#fcfcfc" }}
                          style={{
                            padding: "1.25rem",
                            border: ".125rem dashed #eeeeee",
                            textAlign: "center",
                            width: "100%",
                            height: "10rem",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <input {...getInputProps()} type="file" accept="text/csv" />
                          {isDragActive ? (
                            <p>Drop CSV ...</p>
                          ) : (
                            <p>Drag & Drop a CSV file here, or click to select a file</p>
                          )}
                          {fileMessage && <p className="font-medium">{fileMessage}</p>}
                        </Box>
                        <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", paddingTop: 2 }}>
                          <Button
                            sx={{ textTransform: "none" }}
                            className="text-sm"
                            variant="text"
                            onClick={handleDownloadCSVTemplateClick}
                          >
                            Download Template
                          </Button>
                          <Button
                            className="text-sm"
                            onClick={handleCSVJobSRunSubmit}
                            variant="contained"
                            sx={{ textTransform: "none" }}
                            disabled={isSubmittingCaseInfoForm || bulkRunCount === 0}
                            startIcon={<PlayCircleFilledRounded sx={{ height: "1rem" }}></PlayCircleFilledRounded>}
                          >
                            {`Run ${bulkRunCount > 0 ? `${bulkRunCount} ` : ""}Case${bulkRunCount > 1 ? "s" : ""}`}
                          </Button>
                        </Box>
                      </TabPanel>
                    </TabContext>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={isRunAgentCaseModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-20" onClose={setIsRunAgentCaseModalOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-20 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:w-1/2">
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      className="rounded bg-white text-slate-700 hover:text-slate-900 p-.5 focus:outline-none focus:ring-1 focus:ring-brand-purple focus:ring-offset-1"
                      onClick={() => setIsRunAgentCaseModalOpen(false)}
                    >
                      <span className="sr-only">Close</span>
                      <Close sx={{ fontSize: "1rem" }} className="h-2 w-2" aria-hidden="true" />
                    </button>
                  </div>
                  <Dialog.Title
                    as="h3"
                    className="flex gap-x-3 items-center text-base font-semibold leading-6 text-slate-900 px-5 my-4"
                  >
                    <ViewListRounded sx={{ fontSize: "1.5rem" }} className="text-slate-400 h-6 w-6" />
                    Inputs for Case
                  </Dialog.Title>
                  <div className="mt-5 text-sm">
                    <SelfServeForm
                      agent={agent}
                      selectedEndpoint={selectedEndpoint}
                      onSubmitCallback={(jobId) => {
                        window.location.href = `/jobs/${selectedEndpoint?.agentKey}/${jobId}`;
                        setIsRunAgentCaseModalOpen(false);
                      }}
                      onCloseCallback={() => setIsRunAgentCaseModalOpen(false)}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
