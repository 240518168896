import { useEffect, FormEventHandler, ReactNode, SyntheticEvent, useState, useContext, useLayoutEffect } from "react";
import clsx from "clsx";
import { Button, Tooltip } from "@mui/material";
import { ThumbDown, ThumbUp, ThumbDownOutlined, ThumbUpOutlined } from "@mui/icons-material";
import { useParchaApi } from "@/hooks/useParchaApi";
import FeedbackContext from "@/contexts/FeedbackContext";
import { Endpoint } from "@/types";

const FinalOutput: React.FC<{
  id?: string;
  level: "success" | "failure" | "caution" | "normal";
  message: ReactNode;
  feedbackContent: any;
}> = ({ id, level, message, feedbackContent }) => {
  const contextFeedbackData = useContext(FeedbackContext);
  const [comment, setComment] = useState<string | null>("");
  const [hoveringState, setHoveringState] = useState({ thumbUp: false, thumbDown: false });
  const [selectedAnswer, setSelectedAnswer] = useState<"thumbUp" | "thumbDown" | null>(null);
  const [shouldDisplayForm, setShouldDisplayForm] = useState<boolean>(false);
  const parchaApi = useParchaApi();

  useLayoutEffect(() => {
    if (shouldDisplayForm && id === "final-answer-output") {
      const auditLogElement = document.getElementById("audit-log-container");

      if (auditLogElement) {
        auditLogElement.scrollTo({
          top: auditLogElement.scrollHeight,
          behavior: "smooth",
        });
      }
    }
  }, [shouldDisplayForm]);

  useEffect(() => {
    if (typeof contextFeedbackData?.feedbackData.score !== "undefined") {
      setSelectedAnswer(contextFeedbackData.feedbackData.score ? "thumbUp" : "thumbDown");
      setComment(contextFeedbackData.feedbackData.comment);
    } else {
      setSelectedAnswer(null);
      setComment("");
    }
    setShouldDisplayForm(false);
  }, [contextFeedbackData]);

  const onMouseEnterHandler = (e: SyntheticEvent) => {
    const target = e.target as HTMLButtonElement;
    const name = target.name;

    // This is used to avoid a weird scenario when moving the mouse quickly between the two buttons
    // Without this, they were marked as hovering = 'true'
    const resetEntries = { thumbUp: false, thumbDown: false };
    setHoveringState({
      ...resetEntries,
      [name]: true,
    });
  };

  const onMouseLeaveHandler = (e: SyntheticEvent) => {
    const target = e.target as HTMLButtonElement;
    const name = target.name;

    setHoveringState({
      ...hoveringState,
      [name]: false,
    });
  };

  const handleThumbUpClick = () => {
    if (selectedAnswer === "thumbUp") return;

    const endpoint = contextFeedbackData?.feedbackData.endpoint;
    const jobId = contextFeedbackData?.feedbackData.jobId || feedbackContent.job_id.replaceAll("-", "");

    setSelectedAnswer("thumbUp");

    sendFeedback({
      endpoint,
      jobId,
      comment: "",
      score: true,
    });
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const endpoint = contextFeedbackData?.feedbackData.endpoint;
    const jobId = contextFeedbackData?.feedbackData.jobId || feedbackContent.job_id.replaceAll("-", "");

    if (comment) {
      sendFeedback({
        endpoint,
        jobId,
        score: false,
        comment,
      });
    }
  };

  const sendFeedback = (data: { endpoint: Endpoint; jobId: string; score: boolean; comment: string }) => {
    parchaApi
      .sendFeedback(
        data.endpoint.endpointUrl,
        data.jobId,
        "final_output",
        data.score,
        undefined,
        {
          endpoint: data.endpoint,
          job_id: data.jobId,
          feedback_key: "command_result",
          command_result: feedbackContent,
        },
        data.comment,
      )
      .then(() => {
        contextFeedbackData?.setLatestFeedbackData({
          data: {
            ...contextFeedbackData?.feedbackData.data,
            feedback_key: "command_result",
          },
          comment: data.comment,
          endpoint: data.endpoint,
          job_id: data.jobId,
          score: data.score,
        });
      })
      .catch((err) => {
        console.error("error submitting feedback", err);
      });
  };

  return (
    <div
      id={id}
      className={clsx("flex flex-col gap-y-8 rounded-lg p-5 z-20 text-xs", {
        "bg-red-50": level === "failure",
        "bg-green-50": level === "success",
        "bg-yellow-50": level === "caution",
      })}
    >
      <div
        className={clsx({
          "text-red-700": level === "failure",
          "text-green-700": level === "success",
          "text-yellow-700": level === "caution",
        })}
      >
        {message}
      </div>
      {!contextFeedbackData?.isLoading && (
        <div className="flex gap-x-2">
          <p
            className={clsx("font-semibold text-center leading-4 bg-opacity-[0.08] p-1.5 rounded", {
              "bg-[#15803D] text-green-700": level === "success",
              "bg-[#A16207] text-yellow-700": level === "caution",
              "bg-[#B91C1C] text-red-700": level === "failure",
            })}
          >
            Was this response accurate?
          </p>
          <div className="flex gap-x-0.5">
            <Tooltip title="Correct Response">
              <Button
                name="thumbUp"
                onMouseEnter={onMouseEnterHandler}
                onMouseLeave={onMouseLeaveHandler}
                onFocus={onMouseEnterHandler}
                onBlur={onMouseLeaveHandler}
                sx={{
                  backgroundColor:
                    level === "success"
                      ? "rgba(21, 128, 61, 0.08)"
                      : level === "failure"
                        ? "rgba(185, 28, 28, 0.08)"
                        : level === "caution"
                          ? "rgba(161, 98, 7, 0.08)"
                          : "transparent",
                  minWidth: "1rem",
                  minHeight: "1rem",
                  "&:hover": {
                    backgroundColor:
                      level === "success"
                        ? "rgba(21, 128, 61, 0.2)"
                        : level === "failure"
                          ? "rgba(185, 28, 28, 0.2)"
                          : level === "caution"
                            ? "rgba(161, 98, 7, 0.2)"
                            : "transparent",
                  },
                }}
                onClick={handleThumbUpClick}
              >
                {hoveringState.thumbUp || selectedAnswer === "thumbUp" ? (
                  <ThumbUp
                    sx={{ fontSize: "1rem" }}
                    className={clsx({
                      "text-green-700": level === "success",
                      "text-red-700": level === "failure",
                      "text-yellow-700": level === "caution",
                    })}
                  />
                ) : (
                  <ThumbUpOutlined
                    sx={{ fontSize: "1rem" }}
                    className={clsx({
                      "text-green-700": level === "success",
                      "text-red-700": level === "failure",
                      "text-yellow-700": level === "caution",
                    })}
                  />
                )}
              </Button>
            </Tooltip>
            <Tooltip title="Incorrect Response">
              <Button
                name="thumbDown"
                onMouseEnter={onMouseEnterHandler}
                onMouseLeave={onMouseLeaveHandler}
                onFocus={onMouseEnterHandler}
                onBlur={onMouseLeaveHandler}
                sx={{
                  backgroundColor:
                    level === "success"
                      ? "rgba(21, 128, 61, 0.08)"
                      : level === "failure"
                        ? "rgba(185, 28, 28, 0.08)"
                        : level === "caution"
                          ? "rgba(161, 98, 7, 0.08)"
                          : "transparent",
                  minWidth: "1rem",
                  minHeight: "1rem",
                  "&:hover": {
                    backgroundColor:
                      level === "success"
                        ? "rgba(21, 128, 61, 0.2)"
                        : level === "failure"
                          ? "rgba(185, 28, 28, 0.2)"
                          : level === "caution"
                            ? "rgba(161, 98, 7, 0.2)"
                            : "transparent",
                  },
                }}
                onClick={() => {
                  setSelectedAnswer("thumbDown");
                  setShouldDisplayForm(true);
                }}
              >
                {hoveringState.thumbDown || selectedAnswer === "thumbDown" ? (
                  <ThumbDown
                    sx={{ fontSize: "1rem" }}
                    className={clsx({
                      "text-green-700": level === "success",
                      "text-red-700": level === "failure",
                      "text-yellow-700": level === "caution",
                    })}
                  />
                ) : (
                  <ThumbDownOutlined
                    sx={{ fontSize: "1rem" }}
                    className={clsx({
                      "text-green-700": level === "success",
                      "text-red-700": level === "failure",
                      "text-yellow-700": level === "caution",
                    })}
                  />
                )}
              </Button>
            </Tooltip>
          </div>
          {selectedAnswer === "thumbDown" && !shouldDisplayForm ? (
            <button
              className={clsx("text-center underline justify-self-end", {
                "text-green-700": level === "success",
                "text-yellow-700": level === "caution",
                "text-red-700": level === "failure",
              })}
              onClick={() => setShouldDisplayForm(true)}
            >
              View Details
            </button>
          ) : null}
        </div>
      )}
      {shouldDisplayForm && (
        <form onSubmit={handleSubmit} className="w-full text-xs">
          <div className="flex flex-col gap-3">
            <input
              onChange={(e) => setComment(e.target.value)}
              value={comment || ""}
              className="w-full rounded-md border-1 border-slate-300 placeholder:text-slate-400 placeholder:text-xs text-xs box-border"
              type="text"
              name="comment"
              placeholder="Please provide feedback on how we can improve..."
            />
            <div className="flex gap-2 justify-end text-slate-900">
              <button
                className="p-2 rounded bg-brand-purple text-white disabled:opacity-60"
                type="submit"
                disabled={contextFeedbackData?.feedbackData?.comment === comment}
              >
                Send Feedback
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default FinalOutput;
