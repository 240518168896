import linkedInLogoIcon from "../../assets/linked_in_logo.png";
import facebookLogo from "../../assets/facebook_logo.png";
import parchaLogoIcon from "../../assets/ParchaLogo_Yellow_BlackBg.png";
import crunchbaseLogoIcon from "../../assets/crunchbase_icon.png";
import xLogoIcon from "../../assets/x-logo.png";
import instagramLogo from "../../assets/instagram_logo.png";
import youTubeLogo from "../../assets/youtube_social_circle_red.png";
import { LanguageRounded } from "@mui/icons-material";

const SourcesTag = ({ sources }: { sources: { url: string; type: string }[] }) => {
  return (
    <div className="flex items-center w-fit gap-x-1 bg-white border border-slate-200 rounded-lg p-2 text-xs">
      {sources.map((source) => {
        let icon;
        switch (source.type) {
          case "Facebook":
            icon = facebookLogo;
            break;
          case "LinkedIn":
            icon = linkedInLogoIcon;
            break;
          case "Parcha":
            icon = parchaLogoIcon;
            break;
          case "Crunchbase":
            icon = crunchbaseLogoIcon;
            break;
          case "X":
          case "Twitter":
            icon = xLogoIcon;
            break;
          case "Instagram":
            icon = instagramLogo;
            break;
          case "YouTube":
            icon = youTubeLogo;
            break;
          default:
            icon = null;
        }
        return (
          <a href={source.url} target="_blank">
            {" "}
            {icon ? (
              <img key={source.type} src={icon} alt={source.type} className="w-5 h-5 rounded-full" />
            ) : (
              <LanguageRounded sx={{ fontSize: "1.25rem" }} className="rounded-full" />
            )}
          </a>
        );
      })}
      <span className="ml-1">
        {sources.length} {sources.length === 1 ? "source" : "sources"}
      </span>
    </div>
  );
};

export default SourcesTag;
