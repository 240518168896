// @ts-nocheck
import { twJoin } from "tailwind-merge";
import { CancelRounded, DomainDisabledRounded, WarningRounded } from "@mui/icons-material";
import Alert from "./Alert";
import FindingsLoadingIndicator from "./FindingsLoadingIndicator";

const HighRiskIndustryFindings = ({ checkResult, hideHeader }) => {
  const Header = (
    <div className="flex items-center gap-x-2">
      <DomainDisabledRounded sx={{ fontSize: "1.25rem" }} className="text-slate-700" />
      <span className="text-lg font-semibold">High-Risk Industries</span>
    </div>
  );

  if (!["complete", "error"].includes(checkResult.status) && checkResult.metadata?.business_name) {
    return (
      <div className="flex flex-col gap-y-4">
        {Header}
        <FindingsLoadingIndicator
          message={`Running High-Risk Industries check on ${checkResult.metadata.business_name}...`}
        />
      </div>
    );
  }

  if (!checkResult.verified_data && !checkResult.check_result) {
    return null;
  }

  const verifiedDataIndustriesSet = new Set(
    checkResult?.verified_data?.found_industries?.map((entry) => entry.industry) || [],
  );

  const highRiskIndustriesFound = checkResult?.check_result?.high_risk_industries_match?.high_risk_industries || [];
  const prohibitedIndustriesFound = checkResult?.check_result?.prohibited_industries_match?.prohibited_industries || [];

  return (
    <div className="flex flex-col gap-y-3 w-full">
      {!hideHeader && Header}
      <Alert
        message={checkResult.answer}
        level={
          prohibitedIndustriesFound.length > 0 ? "danger" : highRiskIndustriesFound.length > 0 ? "caution" : "success"
        }
      />
      <div className="flex items-start gap-x-2 text-xs">
        <div className="flex flex-1 flex-col">
          <div className="flex gap-x-2">
            <div
              className={twJoin(
                "rounded-lg flex-1 flex flex-col gap-y-2 p-4",
                prohibitedIndustriesFound?.length > 0 ? "border border-red-600 bg-white" : "bg-slate-50",
              )}
            >
              <span className="font-semibold">Prohibited Industries</span>
              {prohibitedIndustriesFound.length > 0 ? (
                prohibitedIndustriesFound.map((industry, index) => (
                  <span key={index} className="font-semibold text-red-600">
                    {industry}
                  </span>
                ))
              ) : (
                <span className="text-gray-500">No matches found</span>
              )}
            </div>
            <div
              className={twJoin(
                "rounded-lg flex-1 flex flex-col gap-y-2 p-4",
                highRiskIndustriesFound?.length > 0 ? "border border-yellow-600 bg-white" : "bg-slate-50",
              )}
            >
              <span className="font-semibold">High-Risk Industries</span>
              <div className="flex flex-col gap-y-2">
                {highRiskIndustriesFound.length > 0 ? (
                  highRiskIndustriesFound.map((industry, index) => (
                    <span key={index} className="font-semibold text-yellow-600">
                      {industry}
                    </span>
                  ))
                ) : (
                  <span className="text-gray-500">No matches found</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HighRiskIndustryFindings;
