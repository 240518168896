//@ts-nocheck
import { useState } from "react";
import {
  ArrowDownwardRounded,
  PersonSearchRounded,
  ExpandLessRounded,
  ExpandMoreRounded,
  ArrowUpwardRounded,
} from "@mui/icons-material";
import WrappedValue from "./WrappedValue";
import { TopicChip } from "./AdverseMediaV2";
import SourceItem from "./SourceItem";

type AdverseMediaPotentialHit = {
  id: string;
  title: string;
  summary: string;
  topics: string[];
  perpetrator: {
    full_name: string;
    age_as_of_today: number | null;
    associated_countries: string[];
  };
  sources: {
    id: string;
    description: string;
    title: string;
    url: string | null;
    when: string;
    text_content: string;
  }[];
};

const AdverseMediaPotentialHitV2 = (entry: AdverseMediaPotentialHit) => {
  const [showAllArticles, setShowAllArticles] = useState(false);
  const [showPotentialHitData, setShowPotentialHitData] = useState(true);

  return (
    <div className="w-0 min-w-full flex flex-wrap lg:flex-nowrap flex-col flex-wrap text-xs overflow-hidden flex-wrap rounded-xl border border-slate-200">
      <div className="w-full flex items-baseline flex-wrap gap-2 justify-between bg-slate-50 border-b border-slate-2000 rounded-t-xl p-4">
        <button className="flex gap-x-2 w-auto" onClick={() => setShowPotentialHitData(!showPotentialHitData)}>
          <div className="rounded-full h-4 w-4">
            {showPotentialHitData ? (
              <ExpandLessRounded sx={{ fontSize: "1rem" }} />
            ) : (
              <ExpandMoreRounded sx={{ fontSize: "1rem" }} />
            )}
          </div>
          <PersonSearchRounded sx={{ fontSize: "1rem" }} className="text-slate-9000 w-4 h-4" />
          <div className="flex grow-0 flex-wrap gap-y-1 text-slate-700">
            <div className="flex flex-col">
              <span className="font-semibold capitalize">
                {entry.perpetrator.full_name}
                {entry.perpetrator.age_as_of_today ? `, ${entry.perpetrator.age_as_of_today}` : ""}
              </span>
              <span className="font-light text-slate-500 max-w-[30ch] truncate">
                {entry.perpetrator.associated_countries.length > 0
                  ? `${entry.perpetrator.associated_countries
                      .map((country) =>
                        country !== null && typeof country === "object" && "country_name" in country
                          ? country.country_name
                          : country,
                      )
                      .join(", ")}`
                  : ""}
              </span>
            </div>
          </div>
        </button>
        <WrappedValue text="Potential Match" level="normal" />
      </div>
      {showPotentialHitData && (
        <div className="flex flex-col gap-y-3 p-5">
          <div className="flex flex-col gap-y-1">
            <span className="text-sm font-semibold">{entry.title}</span>
            <span className="text-slate-700">{entry.summary}</span>
          </div>

          {entry.topics?.length > 0 && (
            <div className="flex items-center flex-wrap gap-2">
              <span className="font-semibold text-slate-900">Topics</span>
              {entry.topics.map((topic, index) => (
                <TopicChip key={index} topic={topic} />
              ))}
            </div>
          )}

          <div className="grid grid-cols-2 gap-6">
            {entry.when && (
              <div className="flex flex-col gap-y-1 gap-x-2 flex-wrap">
                <span className="font-medium">Year of Event</span>
                <span className="text-slate-700">{entry.when}</span>
              </div>
            )}
            {entry.perpetrator.associated_countries?.length > 0 && (
              <div className="flex flex-col gap-y-1  gap-x-2 flex-wrap">
                <span className="font-medium">Related Locations</span>
                <span className="text-slate-700">
                  {entry.perpetrator.associated_countries
                    .map((country) =>
                      country
                        ? typeof country === "object" && "country_name" in country
                          ? country.country_name
                          : country
                        : country,
                    )
                    .join(", ")}
                </span>
              </div>
            )}
          </div>

          {entry.sources?.length > 0 && showAllArticles
            ? entry.sources.map((source) => {
                return (
                  <SourceItem
                    key={source.id}
                    when={source.when}
                    url={source.url}
                    title={source.title}
                    sourceName={source.source_name}
                    summary={source.description}
                    text={source.text_content}
                    hasPhoto={source.has_images_of_perpetrator}
                    wasRemoved={false}
                  />
                );
              })
            : null}
          {entry?.sources?.length && (
            <button
              type="button"
              disabled={!entry?.sources?.length}
              className="box-border inline-flex items-center gap-x-1.5 rounded-lg px-3 py-2 w-full text-center justify-center border border-solid border-slate-300"
              onClick={() => setShowAllArticles(!showAllArticles)}
            >
              {showAllArticles ? (
                <>
                  {`Hide ${entry?.sources?.length} article${entry?.sources?.length === 1 ? "" : "s"}`}
                  <ArrowUpwardRounded sx={{ fontSize: "1rem" }} className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                </>
              ) : (
                <>
                  <span>{`Show ${entry?.sources?.length ?? 0} Article${entry?.sources?.length === 1 ? "" : "s"}`}</span>
                  <ArrowDownwardRounded sx={{ fontSize: "1rem" }} className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                </>
              )}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default AdverseMediaPotentialHitV2;
