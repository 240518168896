import { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import _ from "lodash";
import { useParchaApi } from "@/hooks/useParchaApi";
import { AgentRun, Endpoint, EndpointsAndTenant } from "@/types";
import AddIcon from "@mui/icons-material/Add";
import CheckContainer from "../V2_Home_Page/CheckContainer";
import ParchaLoadingScreen from "../V2_Home_Page/ParchaLoadingScreen";
import {
  CheckCircle,
  CloseRounded,
  DeleteForever,
  DeleteSweep,
  SettingsRounded,
  SmartToyRounded,
  Refresh,
  PlayCircleFilledRounded,
} from "@mui/icons-material";
import { COLORS } from "@/constants/colors";
import FullWidthTabs from "../TailwindComponents/FullWidthTabs";
import Toggle from "../Toggle";
import { twJoin } from "tailwind-merge";

type Check = {
  id: string;
  name: string;
  check_type: string;
  enabled: boolean;
  showSettings: boolean;
};

const ADVERSE_MEDIA_TOPICS_LIST = [
  "Fraud",
  "Money Laundering",
  "Bribery",
  "Crime",
  "Terorrism",
  "Conterfeit",
  "Corruption",
  "Political Involvement",
  "Financing",
  "Tax Evasion",
  "Mining",
  "Human Smuggling",
  "Human Trafficking",
  "Interpol",
  "OFAC",
];

const Checks = () => {
  const parchaApi = useParchaApi();
  const [stage, setStage] = useState("loading_endpoint");
  const [jobData, setJobData] = useState<AgentRun | null>(null);
  const [searchParams] = useSearchParams();
  const [endpoint, setEndpoint] = useState<Endpoint | null>(null);
  const [availableChecks, setAvailableChecks] = useState<Check[]>([]);
  const [mediaTopics, setMediaTopics] = useState<string[]>(ADVERSE_MEDIA_TOPICS_LIST);
  const [selectedTab, setSelectedTab] = useState("business");
  const [validityPeriod, setValidityPeriod] = useState(12);
  const [notifyAgentSaved, setNotifyAgentSaved] = useState(false);
  const [currJobId, setCurrJobId] = useState<string | null>(null);
  const [startingJob, setStartingJob] = useState(false);

  const commandResult = jobData?.status_messages?.find((message) => message.event === "command_results");
  const auditLogMessages = jobData?.status_messages?.filter((message) =>
    ["api_results", "command_results", "thought"].includes(message.event),
  );
  const commandInstanceId =
    jobData?.status_messages?.length ?? 0 > 0 ? jobData?.status_messages[0].content.command_instance_id : null;
  const mainRef = useRef<null | HTMLDivElement>(null);
  const addNewsSourceRef = useRef<null | HTMLInputElement>(null);
  const addMediaTopicRef = useRef<null | HTMLInputElement>(null);
  const navigate = useNavigate();

  const agentKey = searchParams.get("agent_key");

  const runCheck = async () => {
    if (!endpoint) return;

    setStartingJob(true);

    const self_attested_data = {
      business_name: "FTX",
      registered_business_name: "FTX Trading Ltd.",
      address_of_operation: {
        street_1: "No. 11 Mandolin Place",
        street_2: "Friar's Hill Road",
        city: "Saint John's",
        state: "ANTIGUA AND BARBUDA",
        country_code: "BS",
        postal_code: "00000",
      },
      address_of_incorporation: {
        street_1: "Corporate and Trust Services (Caribbean) Limited",
        street_2: "Friar's Hill Road",
        city: "Saint John's",
        state: "ANTIGUA AND BARBUDA",
        country_code: "BS",
        postal_code: "00000",
      },
      website: "https://ftx.com",
      business_purpose: "Cryptocurrency Exchange",
      description: "FTX is a cryptocurrency exchange built by traders, for traders.",
      industry: "Cryptocurrency",
      partners: ["Alameda Research"],
      customers: ["Jump Trading"],
      source_of_funds: ["Investment", "Revenue"],
      incorporation_documents: [],
      business_ownership_documents: [
        {
          url: "https://parcha-ai-backtest-data.s3.amazonaws.com/parcha/FTX+Cap+Table+Shares.pdf",
          file_name: "FTX+Cap+Table+Shares.pdf",
          source_type: "file_url",
        },
      ],
      proof_of_address_documents: [
        {
          url: "https://parcha-ai-backtest-data.s3.amazonaws.com/parcha/FTX-SEC-FORM-D.pdf",
          file_name: "FTX-SEC-FORM-D.pdf",
          source_type: "file_url",
        },
      ],
      promo_marketing_documents: [],
    };

    const check_args = {
      topics: mediaTopics,
      validity_period_months: validityPeriod,
    };

    const job = await parchaApi.runCheck(endpoint.endpointUrl, "kyb.screening.adverse_media_tool", {
      kyb_schema: {
        id: "parcha_labs",
        self_attested_data,
      },
      check_args,
    });
    setCurrJobId(job.job_id);
  };

  useEffect(() => {
    //load parcha_config from local storage
    const config = localStorage.getItem("parcha_config");
    if (config) {
      const parsedConfig = JSON.parse(config);
      setMediaTopics(parsedConfig.mediaTopics);
      setValidityPeriod(parsedConfig.validityPeriod);
    }
  }, []);

  const saveConfigurationToLocalStorage = () => {
    const config = {
      mediaTopics,
      validityPeriod,
    };
    localStorage.setItem("parcha_config", JSON.stringify(config));
    setNotifyAgentSaved(true);
    setTimeout(() => {
      setNotifyAgentSaved(false);
    }, 3000);
  };

  const fetchEndpoint = async () => {
    const customerAgents = await parchaApi.getCustomerAgents();
    const { endpoints, tenantName } = customerAgents as EndpointsAndTenant;

    // If any client other than Parcha tries to access this page, redirect them to the jobs page
    if (tenantName !== "Parcha") {
      navigate("/jobs");
    }

    if (!agentKey) {
      const firstEndpoint = endpoints[0];
      navigate(`/checks?agent_key=${firstEndpoint.agentKey}`);
    }
    const endpoint = endpoints.find((e) => e.agentKey === agentKey);
    return endpoint;
  };

  const fetchAvailableChecks = async () => {
    if (!endpoint?.endpointUrl) return;

    const availableChecks = await parchaApi.getAvailableChecks(endpoint.endpointUrl);
    return availableChecks;
  };

  useEffect(() => {
    fetchEndpoint().then((fetchedEndpoint) => {
      if (!fetchedEndpoint) return;

      setStage("loading_checks");
      setEndpoint(fetchedEndpoint);
    });
  }, [agentKey]);

  useEffect(() => {
    if (!endpoint?.endpointUrl) {
      return;
    }
    fetchAvailableChecks().then((availableChecks) => {
      setAvailableChecks(availableChecks.map((check: Check) => ({ ...check, enabled: true, showSettings: false })));
      setStage("all_data_loaded");
    });
  }, [endpoint]);

  const fetchLatestMessagesForJob = async (jobId: string) => {
    if (!endpoint?.endpointUrl || !jobId) return;

    const job = await parchaApi.getJobWithStatusMessages(endpoint.endpointUrl, jobId);
    return job;
  };

  useEffect(() => {
    if (!currJobId || jobData?.status_messages?.find((message) => message.event === "command_results")) {
      return;
    }

    if (jobData?.status_messages?.length) {
      setStartingJob(false);
    }

    const intervalId = setInterval(() => {
      fetchLatestMessagesForJob(currJobId).then((jobData) => {
        setJobData(jobData);
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [currJobId, jobData]);

  const businessChecks = availableChecks.filter((check) => check.check_type === "kyb");
  const individualChecks = availableChecks.filter((check) => check.check_type === "kyc");

  const renderChecks = (checks: Check[]) => {
    return checks.map((check) => (
      <div key={check.id} className="flex flex-col">
        <div
          className={twJoin(
            "flex justify-between items-center w-full py-4 px-6 border-slate-200",
            check.showSettings ? "rounded-t-xl border" : "rounded-xl border",
          )}
        >
          <h4>{check.name}</h4>
          <form key={check.id} onSubmit={(e) => e.preventDefault()}>
            <input name="check_id" hidden value={check.id} />
            <input name="endpoint_url" hidden value={endpoint?.endpointUrl} />
            <input hidden name="intent" value="preview-check" />
            <div className="flex gap-x-2 items-center">
              {check.id === "kyb.screening.adverse_media_tool" ? (
                <button
                  className="group"
                  type="button"
                  onClick={() => {
                    const newChecks = availableChecks.map((c) => {
                      if (c.id === check.id) {
                        return { ...c, showSettings: !c.showSettings };
                      }
                      return c;
                    });
                    setAvailableChecks(newChecks);
                  }}
                >
                  <SettingsRounded
                    sx={{ fontSize: "1.5rem" }}
                    className="-ml-0.5 text-brand-purple group-disabled:text-slate-400 group-disabled:cursor-not-allowed"
                    aria-hidden="true"
                  />
                </button>
              ) : null}
              <button
                type="button"
                className="inline-flex items-center gap-x-2 rounded-md bg-green-600 px-[0.75rem] py-[0.38rem] text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-purple disabled:opacity-60 disabled:cursor-not-allowed"
                onClick={runCheck}
              >
                <PlayCircleFilledRounded sx={{ fontSize: "1rem" }} className="-ml-0.5" aria-hidden="true" />
                <span>Preview</span>
              </button>
              {/* <Toggle
                enabled={check.enabled}
                setEnabled={() => {
                  const newChecks = availableChecks.map((c) => {
                    if (c.id === check.id) {
                      return { ...c, enabled: !c.enabled };
                    }
                    return c;
                  });
                  setAvailableChecks(newChecks);
                }}
              /> */}
            </div>
          </form>
        </div>
        {check.showSettings && (
          <div className="flex flex-col gap-y-3 p-4 border-x border-b border-slate-200 text-slate-900 rounded-b-xl divide-y-2">
            <ChecksInfo />
            <div className="flex flex-col gap-y-3">
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="divide-y-2">
                  <div className="flex flex-col gap-y-1 py-4">
                    <p className="font-semibold">Adverse Media Topics List</p>
                    <p className="font-normal text-slate-700 text-sm">
                      These are the default adverse media keywords that are searched for:
                    </p>
                    <div className="border border-slate-300 rounded-lg p-3 mt-4">
                      <div
                        role="textbox"
                        onClick={() => addMediaTopicRef.current?.focus()}
                        className="flex flex-wrap items-center gap-2"
                      >
                        {mediaTopics.map((topic, index) => (
                          <div
                            key={`${topic}${index}`}
                            className="flex text-sm items-center gap-x-1 border bg-slate-50 text-slate-700 border-slate-300 px-2 rounded-full"
                          >
                            {topic}
                            <button
                              type="button"
                              className="focus:ring focus:ring-inset focus:ring-brand-purple"
                              onClick={(e) => {
                                e.stopPropagation();
                                setMediaTopics(mediaTopics.filter((t) => t !== topic));
                              }}
                            >
                              <CloseRounded sx={{ fontSize: "1rem" }} className="text-slate-500" />
                            </button>
                          </div>
                        ))}
                        <div>
                          <label className="sr-only" htmlFor="adverse_media_topic" />
                          <input
                            ref={addMediaTopicRef}
                            type="text"
                            onKeyDown={(e) => {
                              const value = e.currentTarget.value;
                              if (e.key === "Enter" && value.length > 0) {
                                // Removing dups
                                const set = new Set([...mediaTopics, value]);
                                setMediaTopics(Array.from(set));
                                e.currentTarget.value = "";
                              } else if (e.key === "Backspace" && value.length === 0) {
                                setMediaTopics(mediaTopics.slice(0, -1));
                              }
                            }}
                            name="adverse_media_topic"
                            id="adverse_media_topic"
                            className="block box-border w-fit rounded-md text-slate-900 placeholder:text-slate-500 text-xs focus:outline-none focus:ring-0"
                            placeholder="Enter new topic..."
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-y-1 py-4">
                    <p className="font-semibold">Age of Articles</p>
                    <p className="font-normal text-slate-700 text-sm">
                      This is the date of how far back we will return articles (
                      <i>the further back date of the article will impact the accuracy</i>):
                    </p>
                    <div className="flex flex-col gap-y-2 w-fit">
                      <select
                        className="border border-slate-300 rounded-lg shadow-sm text-sm"
                        value={validityPeriod}
                        onChange={(e) => {
                          setValidityPeriod(Number(e.target.value));
                        }}
                      >
                        <option value={12}>1 year back</option>
                        <option value={24}>2 years back</option>
                        <option value={36}>3 years back</option>
                        <option value={48}>4 years back</option>
                        <option value={60}>5 years back</option>
                      </select>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    ));
  };

  return (
    <main
      className="w-full h-full grid grid-rows-[_3.75rem,1fr] font-xs mx-auto h-screen overflow-hidden"
      ref={mainRef}
    >
      {notifyAgentSaved && (
        <div className="mx-auto absolute left-[calc(50vw-6rem)]">
          <div
            className="flex items-center gap-x-2 p-4 mb-4 text-md text-green-700 bg-green-100 rounded-lg"
            role="alert"
          >
            <CheckCircle className="inline flex-shrink-0 w-5 h-5 text-green-700" />
            <span>Your agent has been updated!</span>
          </div>
        </div>
      )}
      {stage === "loading_endpoint" ? (
        <div className="flex justify-center items-center w-full h-full p-10 row-span-2">
          <ParchaLoadingScreen message="Loading Agent..." />
        </div>
      ) : (
        <div className="flex items-center justify-between py-4 px-10 border-b border-slate-200">
          <h1 className="text-xl font-semibold">{endpoint?.agentName}</h1>
          <button
            type="button"
            onClick={() => {
              saveConfigurationToLocalStorage();
            }}
            className="bg-brand-purple text-white px-5 py-3 rounded-md text-sm"
          >
            Save Configuration
          </button>
        </div>
      )}
      <div className="h-full grid grid-cols-2 overflow-hidden">
        {stage === "loading_checks" && (
          <div className="flex justify-center items-center w-full h-full p-10 col-span-2">
            <ParchaLoadingScreen message="Loading Agent Checks..." size="large" />
          </div>
        )}
        {stage === "all_data_loaded" && availableChecks.length > 0 && (
          <>
            <div className="flex flex-col h-full overflow-hidden border-r border-slate-200">
              <div className="flex flex-col gap-y-5 bg-slate-50 px-10 py-5">
                <div className="flex items-center gap-x-3">
                  <SmartToyRounded
                    sx={{
                      backgroundColor: COLORS.parchaBrandPurple,
                      color: "white",
                      fontSize: "2.5rem",
                      padding: ".5rem",
                      borderRadius: "50%",
                    }}
                  />
                  <h2 className="text-lg font-semibold">Checks Playground</h2>
                </div>
              </div>
              <FullWidthTabs
                tabs={[
                  { name: "Business", id: "business", count: 0 },
                  { name: "Individuals", id: "individuals", count: 0 },
                ]}
                selectedTab={selectedTab}
                onTabChangeHandler={setSelectedTab}
                onValueSelectionHandler={undefined}
              />
              <div className="overflow-y-auto p-5 flex flex-col gap-y-4">
                {selectedTab === "business" && renderChecks(businessChecks)}
                {selectedTab === "individuals" && renderChecks(individualChecks)}
              </div>
            </div>
            {startingJob && (
              <div className="flex items-center justify-center w-full h-full">
                <ParchaLoadingScreen message="Starting preview..." />
              </div>
            )}
            {!startingJob && jobData && Object.entries(jobData).length > 0 ? (
              <div className="flex flex-col gap-y-2 items-center w-full px-10 py-5 overflow-y-auto">
                <h4 className="font-semibold">Preview Playground</h4>
                <div className="flex gap-x-2">
                  <button
                    type="button"
                    onClick={() => {
                      setJobData(null);
                      setCurrJobId(null);
                    }}
                    className="mt-2 w-fit inline-flex border border-solid border-slate-300 items-center gap-x-2 rounded-md px-4 py-3 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-purple disabled:opacity-60 disabled:cursor-not-allowed"
                  >
                    <DeleteSweep sx={{ fontSize: "1.5rem" }} className="text-slate-500 -ml-1" />
                    Cleanup Playground
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setJobData(null);
                      runCheck();
                    }}
                    className="mt-2 w-fit inline-flex border border-solid border-slate-300 items-center gap-x-2 rounded-md px-4 py-3 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-purple disabled:opacity-60 disabled:cursor-not-allowed"
                  >
                    <Refresh sx={{ fontSize: "1.5rem" }} className="text-slate-500 -ml-1" />
                    Re-run
                  </button>
                </div>
                <div className="w-full">
                  <CheckContainer
                    title="KYB Adverse Media Screening Tool"
                    agent={{
                      agentName: endpoint?.agentName,
                      agentKey: endpoint?.agentKey,
                      endpointUrl: endpoint?.endpointUrl,
                    }}
                    endpointUrl={endpoint?.endpointUrl}
                    agentRun={jobData}
                    auditLogMessages={auditLogMessages}
                    commandResult={commandResult}
                    messages={jobData.status_messages}
                    commandInstanceId={commandInstanceId}
                  />
                </div>
              </div>
            ) : !startingJob ? (
              <div className="h-full flex items-center justify-center">
                <div className="w-[25rem] flex flex-col gap-y-5 items-center justify-center bg-slate-50 py-10 rounded-lg text-sm">
                  <PlayCircleFilledRounded sx={{ fontSize: "3rem" }} className="text-green-600" />
                  <div className="text-center">
                    <p className="font-semibold">Preview Playground</p>
                    <p className="font-normal text-slate-500">Preview individual checks or the whole agent.</p>
                  </div>
                  <button
                    type="button"
                    className="inline-flex items-center gap-x-2 rounded-md bg-green-600 px-[0.75rem] py-[0.38rem] text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-purple disabled:opacity-60 disabled:cursor-not-allowed"
                    onClick={runCheck}
                  >
                    <PlayCircleFilledRounded sx={{ fontSize: "1rem" }} className="-ml-0.5" aria-hidden="true" />
                    <span>Preview Agent</span>
                  </button>
                </div>
              </div>
            ) : null}
          </>
        )}
      </div>
    </main>
  );
};

const ChecksInfo = () => {
  return (
    <div className="bg-slate-50 p-4 flex flex-col gap-y-4 rounded-md">
      {/* <h3 className="text-md font-semibold">Check Information</h3> */}
      <div className="flex flex-col gap-y-1">
        <h4 className="text-md font-semibold">Overview</h4>
        <p className="text-sm text-slate-700">
          The Adverse Media Screening Tool is a key component in Parcha's KYB (Know Your Business) toolkit, designed to
          screen businesses for any negative media coverage that could indicate potential risks or threats. This tool is
          essential for organizations that need to conduct thorough background checks on businesses to ensure they are
          not associated with activities that could harm their reputation or
          violatehttps://developer.mozilla.org/en-US/docs/Web/CSS/scroll-snap-type compliance regulations.
        </p>
      </div>

      <div className="flex flex-col gap-y-1">
        <h4 className="text-md font-semibold">How this check works?</h4>
        <p className="text-sm text-slate-700">
          Parcha's AI-driven Adverse Media Tool scans through various media sources to identify any adverse mentions of
          a business. It evaluates the content of articles, news reports, and other media to determine if they relate to
          a list of predefined adverse media topics. The AI assesses the relevance and recency of the media hits to
          ensure they are significant and within a specified validity period. If adverse media is found that is recent
          and relevant to the business under investigation, the tool flags these findings for further review.
        </p>
      </div>
    </div>
  );
};

export default Checks;
