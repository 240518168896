// @ts-nocheck
import { CheckRounded, CancelRounded, Pin } from "@mui/icons-material";
import Alert from "./Alert";
import WrappedValue from "./WrappedValue";

const MCCFindings = ({ checkResult, hideHeader }) => {
  if (!checkResult.verified_data) {
    return null;
  }

  const level = checkResult.passed ? "success" : "failure";

  return (
    <div className="w-full text-sm overflow-hidden flex flex-col gap-y-5">
      {!hideHeader && (
        <div className="flex items-center gap-x-2">
          <Pin sx={{ fontSize: "1.25rem" }} className="text-slate-700" />
          <span className="text-lg font-semibold">Merchant Categorization (MCC)</span>
        </div>
      )}
      <Alert message={checkResult.answer} level={checkResult.passed ? "success" : "failure"} />
      <div className="grid  gap-x-3 gap-y-2">
        <div className="flex flex-col gap-4 py-5 px-4">
          <div className="flex items-start">
            <div className="flex flex-col sm:flex-row items-start sm:items-center gap-2">
              {checkResult.check_result?.mcc_code && (
                <div className="flex-shrink-0">
                  <WrappedValue
                    text={
                      <div className="flex gap-x-1 items-center">
                        {level === "success" ? (
                          <CheckRounded sx={{ fontSize: "1rem" }} />
                        ) : level === "failure" ? (
                          <CancelRounded sx={{ fontSize: "1rem" }} />
                        ) : null}
                        <span>{checkResult.check_result?.mcc_code}</span>
                      </div>
                    }
                    level={level}
                  />
                </div>
              )}
              {checkResult.check_result?.mcc_code_description && (
                <div className="mt-1 sm:mt-0 sm:ml-2 break-words">{checkResult.check_result.mcc_code_description}</div>
              )}
            </div>
          </div>

          {checkResult.verified_data?.business_description && (
            <div className="flex flex-col">
              <div className="font-medium">Description:</div>
              <div>{checkResult.verified_data.business_description}</div>
            </div>
          )}

          {checkResult.verified_data?.products_sold && checkResult.verified_data?.products_sold.length > 0 && (
            <div className="flex flex-col gap-2">
              <div className="font-medium">Products Sold:</div>
              <table className="w-full border-collapse">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="border border-gray-300 px-4 py-2 text-left">Product</th>
                    <th className="border border-gray-300 px-4 py-2 text-left">Description</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(
                    checkResult.verified_data?.products_sold.reduce<Record<string, string>>((acc, product) => {
                      if (!acc[product.product]) {
                        acc[product.product] = product.description;
                      }
                      return acc;
                    }, {}),
                  ).map(([product, description], index) => (
                    <tr key={index} className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}>
                      <td className="border border-gray-300 px-4 py-2">{product}</td>
                      <td className="border border-gray-300 px-4 py-2">{description}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MCCFindings;
