import {
  Box,
  Skeleton,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AgentRuns, QueueProps } from "../../types";
// import LogRocket from "logrocket";

import { GridRowSelectionModel } from "@mui/x-data-grid";
import JobStatusTable from "../JobStatusTable";
// import AgentChatSSE from "../AgentChatSSE";
import { useParchaApi } from "@parcha/hooks/useParchaApi";
import { ErrorContext } from "@parcha/contexts/ErrorContext";
import React from "react";

// LogRocket.init(import.meta.env.VITE_LOGROCKET_APP_ID);

export default function JobInspector({ endpoints }: QueueProps) {
  const [agentRuns, setAgentRuns] = useState<AgentRuns>({});
  const [trackedJobIDs, setTrackedJobIDs] = useState<string[]>([]);
  const [visibleJobIDs, setVisibleJobIDs] = useState<GridRowSelectionModel>([]);
  const [dialogs, setDialogs] = useState<{ [id: string]: boolean }>({});
  const [statusMessage, setStatusMessage] = useState(null);

  const parchaApi = useParchaApi();

  const { setError } = useContext(ErrorContext);
  useEffect(() => {
    const agentRunKeys = Object.keys(agentRuns).filter(
      (key) => agentRuns[key].status === "in progress" || agentRuns[key].status === "queued",
    );
    const newTrackedJobIDs = [...new Set([...trackedJobIDs, ...agentRunKeys])];
    if (newTrackedJobIDs.length !== trackedJobIDs.length) {
      setTrackedJobIDs(newTrackedJobIDs);
    }
  }, [agentRuns]);

  useEffect(() => {
    updateJobStatuses();
  }, [endpoints]);

  function updateJobStatuses() {
    if (endpoints) {
      //const prodEndpoints = endpoints.filter(endpoint => endpoint.env === 'prod');
      const uniqueEndpoints = [...new Set(endpoints.map((endpoint) => endpoint.endpointUrl))];
      const newAgentRuns: AgentRuns = {};
      uniqueEndpoints.forEach((endpointUrl) => {
        parchaApi
          .getAllJobs(endpointUrl)
          .then((res) => {
            res.forEach((job) => {
              job.id = job.job_id;
              job.endpoint = endpointUrl;
              job.total_tokens = job.token_count && job.token_count.total_tokens;
              job.total_cost_str = job.token_count && job.token_count.total_cost_str;
              job.statusMessages = job.status_messages;
              job.caseId = job.case_id || job.input_payload.id;
              newAgentRuns[job.id] = job;
            });
            setAgentRuns(newAgentRuns);
          })
          .catch((error) => setError(error));
      });
    }
  }

  // useEffect(() => {
  //   if(visibleJobIDs.length > 0){
  //     const selectedJobs = Object.values(agentRuns).filter(run => visibleJobIDs.includes(run.jobId));

  const isDialogOpen = (dialogId: string): boolean => {
    return !!dialogs[dialogId];
  };

  const closeDialog = (dialogId: string) => {
    setDialogs((prevDialogs) => ({
      ...prevDialogs,
      [dialogId]: false,
    }));
  };

  const openDialog = (dialogId: string) => {
    setDialogs((prevDialogs) => ({
      ...prevDialogs,
      [dialogId]: true,
    }));
  };

  const handleDeleteAllJobs = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const endpointAgentKey = event.currentTarget.dataset.endpointAgentKey;
    if (!endpointAgentKey) return;

    const { endpoint, agentKey } = JSON.parse(endpointAgentKey);
    parchaApi
      .keepOnlyCompleteJobs(endpoint, agentKey)
      .then((res) => {
        setStatusMessage(res.message);
        updateJobStatuses();
        closeDialog(JSON.stringify({ endpoint: endpoint, agentKey: agentKey }));
      })
      .catch((error) => {
        setError(error);
      });
  };

  return (
    <Box sx={{ minWidth: "100%", overflow: "hidden" }}>
      {statusMessage && <Alert severity="success">{statusMessage}</Alert>}
      <Box sx={{ padding: "8px" }}>
        {Object.values(agentRuns).length > 0 ? (
          <>
            {Object.entries(
              Object.values(agentRuns).reduce((acc: any, run: any) => {
                const key = JSON.stringify({ endpoint: run.endpoint, agentKey: run.agent_key });
                if (!acc[key]) {
                  acc[key] = [];
                }
                acc[key].push(run);
                return acc;
              }, {}),
            ).map(([endpointAgentKey, runs]: [string, any]) => (
              <React.Fragment key={endpointAgentKey}>
                <Typography variant="h5">{`Jobs History for ${endpointAgentKey}`}</Typography>
                <Button onClick={() => openDialog(endpointAgentKey)}>Delete All non Succesful Jobs</Button>
                <Dialog
                  open={isDialogOpen(endpointAgentKey)}
                  onClose={() => closeDialog(endpointAgentKey)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">{"Delete All Jobs"}</DialogTitle>
                  <DialogContent>Are you sure you would like to delete all jobs not completed?</DialogContent>
                  <DialogActions>
                    <Button onClick={() => closeDialog(endpointAgentKey)} color="primary">
                      No
                    </Button>
                    <Button
                      onClick={handleDeleteAllJobs}
                      color="primary"
                      autoFocus
                      variant="contained"
                      data-endpoint-agent-key={endpointAgentKey}
                    >
                      Yes
                    </Button>
                  </DialogActions>
                </Dialog>
                <JobStatusTable
                  rows={(runs as any[]).filter((run) => run.status !== "deleted")}
                  setRowSelectionModel={setVisibleJobIDs}
                  enableCheckboxSelection={true}
                  showAdminColumns={true}
                />
              </React.Fragment>
            ))}
          </>
        ) : (
          <Box>
            <Skeleton
              sx={{ marginTop: "10px", bgcolor: "rgba(93, 95, 239, .1)" }}
              variant="rectangular"
              width={"100%"}
              height={350 / 10}
              animation="wave"
            />
            <Skeleton
              sx={{ marginTop: "10px" }}
              variant="rectangular"
              width={"100%"}
              height={350 / 10}
              animation="wave"
            />
            <Skeleton
              sx={{ marginTop: "10px", bgcolor: "rgba(93, 95, 239, .1)" }}
              variant="rectangular"
              width={"100%"}
              height={350 / 10}
              animation="wave"
            />
            <Skeleton
              sx={{ marginTop: "10px" }}
              variant="rectangular"
              width={"100%"}
              height={350 / 10}
              animation="wave"
            />
            <Skeleton
              sx={{ marginTop: "10px", bgcolor: "rgba(93, 95, 239, .1)" }}
              variant="rectangular"
              width={"100%"}
              height={350 / 10}
              animation="wave"
            />
            <Skeleton
              sx={{ marginTop: "10px" }}
              variant="rectangular"
              width={"100%"}
              height={350 / 10}
              animation="wave"
            />
            <Skeleton
              sx={{ marginTop: "10px", bgcolor: "rgba(93, 95, 239, .1)" }}
              variant="rectangular"
              width={"100%"}
              height={350 / 10}
              animation="wave"
            />
          </Box>
        )}
      </Box>
      <Box sx={{ padding: "8px", width: "100%", overflowX: "auto" }}>
        <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "nowrap", minWidth: "100%" }}>
          {visibleJobIDs.length > 0 &&
            visibleJobIDs.map((jobId, index) => {
              const job = agentRuns[jobId];
              const fromTimestamp = new Date(Number(job.started_at) * 1000 + "Z").getTime();
              const toTimestamp = fromTimestamp + 30 * 60 * 1000;
              return (
                <div style={{ width: "560px", flexShrink: 0 }} key={index}>
                  <a
                    href={`https://us5.datadoghq.com/logs?query=%40job_id%3A${jobId}%20&cols=host%2Cservice&index=%2A&messageDisplay=inline&refresh_mode=sliding&stream_sort=desc&viz=stream&from_ts=${fromTimestamp}&to_ts=${toTimestamp}&live=false`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Typography fontSize={"16px"} fontWeight={"bold"}>
                      ID:{jobId}
                    </Typography>
                  </a>
                  <Typography fontSize={"12px"}>Case ID:{job.case_id}</Typography>
                  {/* <AgentChatSSE agentRun={job} isParchaUser={false} /> */}
                </div>
              );
            })}
        </Box>
      </Box>
    </Box>
  );
}
