import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItemText,
  Menu,
  MenuItem,
  Tab,
  styled,
} from "@mui/material";
import { getFileType } from "@parcha/utils";
import { useParchaApi } from "@parcha/hooks/useParchaApi";
import type {
  Agent,
  AgentRun,
  AgentStatus,
  CommandResult,
  FinishPlanOutputSchema,
  JobMetadata,
  OutputSchema,
  ParchaDocument,
} from "@parcha/types";
import * as Chat from "../Chat";
import JobInfoCard from "../JobInfo";
import { ErrorContext } from "@/contexts/ErrorContext";
import BusinessSelfAttestedDataCard from "./BusinessSelfAttestedDataCard";
import IndividualSelfAttestedDataCard from "./IndividualSelfAttestedDataCard";

interface AgentChatProps {
  agent: Agent;
  agentRun: AgentRun;
  feedbackData?: { [key: string]: any };
  isParchaUser?: boolean;
  isLegacyJob?: boolean;
  selectedTabValue: string;
  setSelectedTabValue: Dispatch<SetStateAction<string>>;
  shouldDisplayTabSelector?: boolean;
  shouldDisplayAuditLog?: boolean;
}

interface StyledTabProps {
  label: string;
  value: string;
  disabled?: boolean;
}

export const AntTabList = styled(TabList)({
  borderBottom: "1px solid #e8e8e8",

  "& .MuiTabs-flexContainer": {
    justifyContent: "center",
  },

  "& .MuiTabs-indicator": {
    backgroundColor: "#5D5FEF",
    width: "100%",
  },
});

export const AntTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: "none",
  minWidth: 0,
  [theme.breakpoints.up("sm")]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightRegular,

  // marginRight: theme.spacing(1),
  color: "#0F172A",
  "&:hover": {
    color: "#475569",
    opacity: 1,
  },
  "&.Mui-selected": {
    color: "#5D5FEF",
    fontWeight: theme.typography.fontWeightMedium,
  },
  "&.Mui-focusVisible": {
    backgroundColor: "#5D5FEF",
    color: "#fff",
  },
}));

//TODO @miguel: add messages and a job id as optional props,
// if messages is in, just display the messages, if job id is in,
// then gather the chat messages from the web server using the job (or the status of the job)
export default function AgentChatSSE({
  agent,
  agentRun,
  feedbackData = {},
  isParchaUser = false,
  isLegacyJob = false,
  selectedTabValue,
  setSelectedTabValue,
  shouldDisplayTabSelector,
}: AgentChatProps) {
  const [jobMetadata, setJobMetadata] = useState<JobMetadata | undefined>(undefined);
  const [generatedRequest, setGeneratedRequest] = useState<string | undefined>(); //TODO: set schema
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [prevStatus, setPrevStatus] = useState<string | undefined>(undefined);
  const [copyButtonText, setCopyButtonText] = useState<string>("Copy to Clipboard");
  // Action Dialogs
  const [isGeneratedRequestDialogOpen, setIsGeneratedRequestDialogOpen] = useState<boolean>(false);
  const [isDeleteJobDialogOpen, setIsDeleteJobDialogOpen] = useState<boolean>(false);
  const [friendlyStatusMessage, setFriendlyStatusMessage] = useState<AgentStatus | undefined>(undefined);

  const { setError } = useContext(ErrorContext);

  const parchaApi = useParchaApi();

  feedbackData["jobId"] = agentRun.id;
  feedbackData["caseId"] = agentRun.case_id || agentRun.input_payload?.id;
  // Handle close event for the menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Handle close event for Generated Request dialog
  const handleCloseGeneratedRequestDialog = () => {
    setIsGeneratedRequestDialogOpen(false);
  };

  // Handle close event for Delete Job dialog
  const handleCloseDeleteJobDialog = () => {
    setIsDeleteJobDialogOpen(false);
  };

  // Handle copy to clipboard event
  const handleCopyToClipboard = () => {
    if (generatedRequest) {
      navigator.clipboard.writeText(generatedRequest).then(() => {
        setCopyButtonText("Copied!");
        setTimeout(() => {
          setCopyButtonText("Copy to Clipboard");
        }, 3e3);
      });
    }
  };

  const handleDeleteJobActionClick = () => {
    setIsDeleteJobDialogOpen(true);
  };

  const handleRetryJobClick = () => {
    const input = {
      agent_key: feedbackData.endpoint.agentKey,
      kyb_schema: agent.agent_type === "kyb" ? agentRun.input_payload : undefined,
      kyc_schema: agent.agent_type === "kyc" ? agentRun.input_payload : undefined,
      run_in_parallel: true,
    };

    parchaApi
      .enqueueAgent(feedbackData.endpoint.endpointUrl, input)
      .then((res) => {
        window.location.href = `/jobs/${feedbackData.endpoint.agentKey}?jobId=${res.job_id}`;
      })
      .catch(handleApiException)
      .finally(() => {
        setAnchorEl(null);
      });
  };

  const handleApiException = (error: Error) => {
    setError(error);
  };

  const deleteJob = async () => {
    if (agentRun.id) {
      await parchaApi.deleteJob(feedbackData.endpoint.endpointUrl, agentRun.id);
      // using replace:true to avoid returning to a delete job URL when back button is clicked
      // Perform a hard refresh
      window.location.href = `/jobs/${feedbackData.endpoint.agentKey}`;
    }
  };

  function makeFriendlyStatusMessage(): AgentStatus | undefined {
    if (agentRun) {
      switch (agentRun.status) {
        case "queued":
          return {
            status: "This job is queued. Waiting for the next agent to become available...",
            is_agent_status: true,
          };
        case "complete":
        case "error":
        case "failed":
          return undefined;
        case "in progress":
          const lastStatusMessage = agentRun.status_messages[agentRun.status_messages.length - 1];
          if (lastStatusMessage?.event === "status") {
            return lastStatusMessage.content as AgentStatus;
          }
          return agentRun.loading_message ?? undefined;
        default:
          return undefined;
      }
    }
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    if (newValue !== selectedTabValue) {
      setSelectedTabValue(newValue);
    }
  };

  const createMetadata = (agentRun: AgentRun): JobMetadata => {
    const finalAnswer =
      agentRun.status_messages?.length > 0 ? agentRun.status_messages[agentRun.status_messages.length - 1] : undefined;
    const finalAnswerCommandResults = finalAnswer?.content as CommandResult;
    const finalAnswerOutput = finalAnswerCommandResults?.output as FinishPlanOutputSchema;
    const documents =
      agentRun.status_messages &&
      agentRun.status_messages
        .map((message) => {
          if (message.event == "command_results") {
            const commandResults = message.content as CommandResult;
            const output = commandResults.output as OutputSchema;
            if (output && output.url && output.button_label) {
              const fileName = output.url.split("/").pop()?.split("?")[0];
              const docType = getFileType(output.url);
              return {
                url: output.url,
                name: output.button_label,
                description: fileName,
                type: docType,
              } as ParchaDocument;
            }
          }
          return undefined;
        })
        .filter((document): document is ParchaDocument => document !== undefined);
    const metadata: JobMetadata = {
      agentRun: agentRun,
      recommendation: finalAnswerOutput ? finalAnswerOutput.recommendation : "",
      documents: documents,
    };
    return metadata;
  };

  useEffect(() => {
    if (agentRun && agentRun.status == "complete") {
      if (prevStatus !== "complete") {
        setPrevStatus("complete");
      }
      const finalAnswer = agentRun.status_messages[agentRun.status_messages.length - 1];
      if (finalAnswer?.event === "final_answer") {
        const finalAnswerCommandResults = finalAnswer.content as CommandResult;
        const finalAnswerOutput = finalAnswerCommandResults.output as FinishPlanOutputSchema;
        // small hack for now to ensure generated requests render something.
        if (finalAnswerOutput.output && Object.keys(finalAnswerOutput.output)?.length !== 0) {
          if (finalAnswerOutput.output.email) {
            setGeneratedRequest(finalAnswerOutput.output.email.message);
          } else {
            setGeneratedRequest(finalAnswerOutput.output);
          }
        }
      }
    } else {
      setPrevStatus(agentRun.status);
    }
    setJobMetadata(createMetadata(agentRun));
    setFriendlyStatusMessage(makeFriendlyStatusMessage());
  }, [agentRun]);

  const notStatusEventMessages =
    agentRun.status_messages && agentRun.status_messages.filter((message) => message.event !== "status");

  const inputMessages =
    notStatusEventMessages?.filter((message) => {
      const commandResult = message.content as CommandResult;
      const output = commandResult?.output as OutputSchema;
      const payload = output?.payload;
      const type = payload?.type;

      return (message.event === "api_results" && type?.includes("SelfAttestedData")) ?? false;
    }) || [];

  return (
    <Box height={"100%"}>
      <Menu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{ zIndex: 9999 }}
      >
        <MenuItem
          onClick={() => {
            setIsGeneratedRequestDialogOpen(true);
            setAnchorEl(null);
          }}
          disabled={!generatedRequest}
        >
          <ListItemText primary="Generate Customer Request" />
        </MenuItem>
        <MenuItem onClick={handleDeleteJobActionClick}>
          <ListItemText primary="Delete Job" />
        </MenuItem>
        <MenuItem onClick={handleRetryJobClick}>
          <ListItemText primary="Retry Job" />
        </MenuItem>
      </Menu>

      <Dialog
        open={isGeneratedRequestDialogOpen}
        onClose={handleCloseGeneratedRequestDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Customer Request</DialogTitle>
        <DialogContent>
          <DialogContentText>{generatedRequest && <ReactMarkdown>{generatedRequest}</ReactMarkdown>}</DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: "0 24px 24px 0" }}>
          <Button onClick={handleCloseGeneratedRequestDialog} color="secondary" variant="contained">
            Cancel
          </Button>
          <Button onClick={handleCopyToClipboard} color="primary" variant="contained">
            {copyButtonText}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isDeleteJobDialogOpen} onClose={handleCloseDeleteJobDialog} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Are you sure you want to delete this job?</DialogTitle>
        <DialogContent>
          <DialogContentText>Once you delete the job you will no longer have access to it.</DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: "0 24px 24px 0" }}>
          <Button onClick={handleCloseDeleteJobDialog} color="secondary" variant="contained">
            Nevermind
          </Button>
          <Button onClick={deleteJob} color="primary" variant="contained">
            Yes, delete it
          </Button>
        </DialogActions>
      </Dialog>

      <TabContext value={selectedTabValue}>
        {shouldDisplayTabSelector ? (
          <>
            <AntTabList
              sx={{ display: "flex", justifyContent: "center", height: "55px", width: "100%" }}
              onChange={handleTabChange}
            >
              {inputMessages.length > 0 && !isLegacyJob ? (
                <AntTab sx={{ flex: "1 1 auto" }} label="Inputs" {...a11yProps(0)} value="inputs" />
              ) : null}
              {isLegacyJob ? (
                <AntTab sx={{ flex: "1 1 auto" }} label="Audit Log" {...a11yProps(0)} value="audit_log" />
              ) : null}
              <AntTab sx={{ flex: "1 1 auto" }} label="Job Details" {...a11yProps(1)} value="job_details" />
            </AntTabList>
          </>
        ) : null}

        <TabPanel
          value="inputs"
          sx={{
            height: "calc(100vh - 55px)",
            overflowY: "auto",
          }}
        >
          <div className="flex flex-col gap-y-4">
            {inputMessages.map((message) => {
              const commandResults = message.content as CommandResult;
              const output = commandResults.output as OutputSchema;
              const payload = output?.payload;
              const didPass = ["true", "pass", "passed", "yes"].includes(output?.passed?.toString().toLowerCase());
              const level = typeof output?.passed === "boolean" ? (didPass ? "success" : "failure") : "normal";

              switch (output.payload.type) {
                case "SelfAttestedData": {
                  return (
                    <BusinessSelfAttestedDataCard
                      key={message.id}
                      caseId={feedbackData.caseId}
                      endpointUrl={feedbackData.endpoint.endpointUrl}
                      isParchaUser={isParchaUser}
                      level={level}
                      payload={payload}
                      individuals={agentRun?.input_payload?.associated_individuals}
                    />
                  );
                }
                case "KYCSelfAttestedData": {
                  return (
                    <IndividualSelfAttestedDataCard
                      key={message.id}
                      caseId={feedbackData.caseId}
                      endpointUrl={feedbackData.endpoint.endpointUrl}
                      isParchaUser={isParchaUser}
                      level={level}
                      payload={payload}
                    />
                  );
                }
                default:
                  return null;
              }
            })}
          </div>
        </TabPanel>
        {jobMetadata && (
          <TabPanel value="job_details" sx={{ padding: "8px" }}>
            <JobInfoCard jobMetadata={jobMetadata} />
          </TabPanel>
        )}
        <TabPanel
          value="audit_log"
          sx={{
            padding: "0",
            paddingRight: "0.5rem",
            marginTop: "1.25rem",
            height: friendlyStatusMessage === undefined ? "calc(100vh - 10rem)" : "calc(100vh - 12rem)",
          }}
        >
          {/* {showScrollDown && <Chat.ScrollDown onClick={onScrollIntoView} />} */}
          {friendlyStatusMessage ? <Chat.StatusUI id="status-message" messageContents={friendlyStatusMessage} /> : null}
          {notStatusEventMessages?.length > 0 ? (
            <Chat.Content
              isLegacyJob={isLegacyJob}
              isParchaUser={isParchaUser || false}
              jobStatus={agentRun.status}
              statusMessages={notStatusEventMessages}
              feedbackData={feedbackData}
            />
          ) : null}
        </TabPanel>
      </TabContext>
    </Box>
  );
}
