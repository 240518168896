import { ReactNode } from "react";

type FindingsSectionProps = {
  title: string;
  content: ReactNode;
};

const FindingsSection = ({ title, content }: FindingsSectionProps) => {
  return (
    <div className="flex flex-col gap-y-2 text-slate-700">
      <h2 className="text-md font-semibold">{title}</h2>
      {content}
    </div>
  );
};

export default FindingsSection;
