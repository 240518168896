import { Box, Link } from "@mui/material";
import { COLORS } from "../../../constants/colors";
import styles from "./styles.module.css";
import { AgentStatus } from "../../../types";
import LaunchIcon from "@mui/icons-material/LaunchRounded";

const CheckStatusMessage = ({
  messageContents,
  className = "",
}: {
  messageContents: AgentStatus;
  className?: string;
}) => {
  const agentStatusContent = messageContents;
  const shouldShowLink = !!agentStatusContent.url;

  return (
    <div className="flex items-center gap-3 p-4">
      <Box
        className={styles.loader}
        component="img"
        src={"/working_loader.svg"}
        alt="logo"
        width={20}
        sx={{
          padding: "0",
          animationName: "spin",
          animationTimingFunction: "linear",
          animationIterationCount: "infinite",
          animationDuration: "2.5s",
          animationDelay: "0.5s",
          "@keyframes spin": {
            "0%": { transform: "rotate(0deg)" },
            "100%": { transform: "rotate(360deg)" },
          },
        }}
      />

      <span className="text-xs line-clamp-1">{agentStatusContent.status}</span>

      {shouldShowLink ? (
        <Box
          flexBasis="100%"
          flexShrink="1"
          flexGrow="0"
          component="div"
          fontSize=".75rem"
          display="flex"
          gap="0.15rem"
          alignItems="center"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
          justifySelf="flex-end"
        >
          <Link
            sx={{
              textDecoration: "none",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
            href={agentStatusContent.url}
            target="__blank"
            rel="noopener noreferrer"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            overflow="hidden"
          >
            {agentStatusContent.button_label}
          </Link>
          <LaunchIcon width="16" fontSize="inherit" sx={{ color: COLORS.parchaBrandPurple }} />
        </Box>
      ) : null}
    </div>
  );
};

export default CheckStatusMessage;
