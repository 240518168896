import { ImageSearchRounded } from "@mui/icons-material";

type WebsiteViewProps = {
  websiteUrl: string;
  websiteLabel: string;
  screenshotUrl?: string | null;
};

const WebsiteCard = ({ websiteUrl, websiteLabel, screenshotUrl }: WebsiteViewProps) => {
  if (
    ((!websiteUrl || !websiteUrl.length) && (!websiteLabel || !websiteLabel.length)) ||
    websiteUrl?.startsWith("https://www.google.com/search")
  ) {
    return null;
  }

  return (
    <div className="w-full flex justify-center text-xs">
      <div className="max-w-full grow flex flex-col border border-slate-200 rounded-lg p-[0.125rem] gap-[0.125rem]">
        {websiteUrl.startsWith("https://www.google.com/search") ? null : websiteUrl ? (
          <a href={websiteUrl} target="_blank" rel="noreferrer">
            {screenshotUrl ? <img className="rounded-md" src={screenshotUrl} /> : <NoImageFound />}
          </a>
        ) : screenshotUrl ? (
          <img
            className="rounded-md max-h-[10rem] object-scale-down w-full min-w-full"
            src={screenshotUrl}
            onError={({ currentTarget }) => (currentTarget.style.display = "none")}
          />
        ) : (
          <div className="w-full min-w-full grow">
            <NoImageFound />
          </div>
        )}
        {websiteLabel ? (
          <div className="bg-slate-100 text-center whitespace-nowrap block text-ellipsis text-slate-700 py-2 px-5 rounded-b-md line-clamp-1 overflow-hidden">
            {websiteLabel}
          </div>
        ) : null}
      </div>
    </div>
  );
};

const NoImageFound = () => {
  return (
    <div className="flex flex-col items-center justify-center gap-y-2 p-12 text-slate-500 bg-slate-200 rounded-t-md">
      <ImageSearchRounded sx={{ fontSize: "2rem" }} />
      <span className="font-semibold">No website image found...</span>
    </div>
  );
};

export default WebsiteCard;
