import linkedInLogo from "../../assets/linked_in_logo.png";

type LinkedInCompanyCardProps = {
  logoUrl: string | null;
  name: string | null;
  industry: string | null;
  linkedInUrl: string | null;
  location: string | null;
  employeesCount: string | null;
  description: string | null;
  overview: string | null;
  url: string | null;
};

const LinkedInCompanyCard = ({
  description,
  employeesCount,
  industry,
  linkedInUrl,
  location,
  logoUrl,
  name,
  overview,
  url,
}: LinkedInCompanyCardProps) => {
  if (!name && !linkedInUrl) return null;

  return (
    <div className="w-full rounded-xl text-slate-900 text-xs p-4 bg-slate-50 overflow-hidden">
      <div className="flex flex-col gap-y-5">
        <div className="flex flex-col gap-y-4">
          <div className="flex items-center justify-between gap-x-2">
            <div className="flex items-center gap-x-2">
              <img src={linkedInLogo} alt="LinkedIn Logo" className="w-4 h-4" />
              <span className="font-semibold">LinkedIn</span>
            </div>
            <div className="h-6.5 min-w-[6rem] flex justify-center items-center rounded-full bg-slate-50 border border-[#334155]/20 py-1 px-2 text-slate-700">
              Social Media
            </div>
          </div>
          {linkedInUrl && (
            <a
              href={linkedInUrl}
              target="_blank"
              rel="noreferrer"
              className="max-w-fit text-brand-purple truncate hover:underline"
            >
              {linkedInUrl}
            </a>
          )}
          <div className="flex gap-x-3">
            {logoUrl && (
              <img
                src={logoUrl}
                alt="Company Logo"
                className="w-12 h-12 rounded-lg object-cover"
                onError={({ currentTarget }) => (currentTarget.style.display = "none")}
              />
            )}
            <div className="flex flex-col gap-y-0.5 overflow-hidden">
              <div className="flex flex-wrap items-baseline gap-x-1">
                <span className="text-base text-bold">{name}</span>
                <span className="text-slate-500">{url}</span>
              </div>
              {industry && <div className="text-xs text-slate-700">{industry}</div>}
              {location && <div className="text-slate-500">{location}</div>}
              {employeesCount && <div className="text-slate-500">{employeesCount} employees</div>}
            </div>
          </div>
        </div>
        {description && <span className="text-slate-500 italic">"{description}"</span>}
        {overview && (
          <div className="flex flex-col gap-y-1">
            <span className="font-semibold">Overview</span>
            <p className="text-slate-700">{overview}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default LinkedInCompanyCard;
