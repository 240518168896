import {
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import { useEffect, useState } from "react";
import { AgentRow, QueueProps } from "../../types";
// import LogRocket from "logrocket";
import { useContext } from "react";
import { ErrorContext } from "@parcha/contexts/ErrorContext";
import { Close } from "@mui/icons-material";
import AgentsTable from "../AgentsTable";
import { useParchaApi } from "@parcha/hooks/useParchaApi";

export default function WorkerInspector({ endpoints }: QueueProps) {
  const [workersTableData, setWorkersTableData] = useState<{ endpointUrl: string; data: AgentRow[] }[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [statusMessage, setStatusMessage] = useState<string | null>(null);
  const [updateInterval, setUpdateInterval] = useState<NodeJS.Timeout | null>(null);

  const parchaApi = useParchaApi();
  const { setError } = useContext(ErrorContext);
  const INTERVAL = 5000;
  const handleApiException = (error: Error) => {
    setError(error);
  };

  const handleKillAllWorkers = async (endpointUrl: string) => {
    setOpenDialog(false);
    try {
      const response = await parchaApi.killAllWorkers(endpointUrl);
      setStatusMessage(response.message);
      await setUpdateWorkersLoop(endpointUrl);
    } catch (error) {
      handleApiException(error);
    }
  };

  const setUpdateWorkersLoop = async (endpointUrl: string) => {
    updateInterval && clearInterval(updateInterval);
    parchaApi
      .getWorkers(endpointUrl)
      .then((res: AgentRow[]) => {
        setWorkersTableData([{ endpointUrl: endpointUrl, data: res }]);
      })
      .catch(handleApiException);

    const interval = setInterval(() => {
      parchaApi
        .getWorkers(endpointUrl)
        .then((res: AgentRow[]) => {
          setWorkersTableData([{ endpointUrl: endpointUrl, data: res }]);
        })
        .catch(handleApiException);
    }, INTERVAL);
    setUpdateInterval(interval);
  };

  useEffect(() => {
    if (endpoints) {
      [...new Set(endpoints.map((endpoint) => endpoint.endpointUrl))].forEach((endpointUrl) => {
        setUpdateWorkersLoop(endpointUrl);
      });
    }
  }, [endpoints]);

  useEffect(() => {
    statusMessage && setTimeout(() => setStatusMessage(null), INTERVAL);
  }, [statusMessage]);

  return (
    <Box position="relative" overflow="hidden" sx={{ height: "100%" }}>
      {statusMessage && (
        <Alert
          severity="info"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setStatusMessage(null);
              }}
            >
              <Close fontSize="inherit" />
            </IconButton>
          }
        >
          {statusMessage}
        </Alert>
      )}
      <Box sx={{ padding: "8px" }}>
        {workersTableData.length > 0 &&
          workersTableData.map(({ endpointUrl, data }) =>
            data.length > 0 ? (
              <Box key={endpointUrl}>
                <Typography variant="h5">{endpointUrl}</Typography>
                <Button onClick={() => setOpenDialog(true)}>Kill All Workers</Button>
                <Dialog
                  open={openDialog}
                  onClose={() => setOpenDialog(false)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">{"Kill All Workers"}</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Are you sure you&apos;d like to kill all the workers? They&apos;ll resuscitate but asking just in
                      case.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setOpenDialog(false)}>No</Button>
                    <Button onClick={() => handleKillAllWorkers(endpointUrl)} autoFocus>
                      Yes
                    </Button>
                  </DialogActions>
                </Dialog>
                <AgentsTable rows={data} />
              </Box>
            ) : (
              <>
                <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                  <iframe
                    src="https://giphy.com/embed/JoV2BiMWVZ96taSewG"
                    width="960"
                    height="540"
                    frameBorder="0"
                    className="giphy-embed"
                    allowFullScreen
                  ></iframe>
                </Box>
              </>
            ),
          )}
      </Box>
    </Box>
  );
}
