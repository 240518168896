import { snakeCaseToProperCase } from "@/utils";
import { CancelRounded, CheckCircle, Policy } from "@mui/icons-material";
import { twJoin } from "tailwind-merge";

export type PolicyType = {
  match_type: string;
  url: string | null;
  match: boolean;
  explanation: string | null;
};

const WebsitePolicyCard = ({ match_type, url, match, explanation }: PolicyType) => {
  let urlToUse = url;
  if (url && !url.startsWith("http")) {
    urlToUse = `http://${url}`;
  }

  return (
    <div
      className={twJoin("flex flex-col gap-y-2 border rounded-lg p-3", match ? "border-green-600" : "border-red-600")}
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-x-2">
          <Policy sx={{ fontSize: "1rem" }} className="text-slate-600" />
          {urlToUse ? (
            <a href={urlToUse} target="_blank" rel="noreferrer" className="text-brand-purple">
              {snakeCaseToProperCase(match_type)}
            </a>
          ) : (
            <span className="text-slate-700">{snakeCaseToProperCase(match_type)}</span>
          )}
        </div>

        {match ? (
          <CheckCircle sx={{ fontSize: "1rem" }} className="text-green-600" />
        ) : (
          <CancelRounded sx={{ fontSize: "1rem" }} className="text-red-600" />
        )}
      </div>
      {explanation && <p className="text-slate-700">{explanation}</p>}
    </div>
  );
};

export default WebsitePolicyCard;
