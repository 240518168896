import { GridColDef } from "@mui/x-data-grid";
import { AgentRow } from "@parcha/types";
import { useEffect, useState, FC } from "react";
import { ParchaDataGrid } from "../ParchaDataGrid";

interface AgentsTableProps {
  rows: AgentRow[];
}

const JobStatusTable: FC<AgentsTableProps> = ({ rows }) => {
  // component logic here
  const [tableData, setTableData] = useState<AgentRow[]>([]);

  useEffect(() => {
    if (rows) {
      setTableData(rows);
    }
  }, [rows]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Worker ID",
      flex: 1,
      align: "left",
      headerAlign: "left",
    },
    { field: "status", headerName: "Status", flex: 1, align: "left", headerAlign: "left" },
    // {
    //   field: 'queues',
    //   headerName: 'Queue',
    //   renderCell: (params: GridRenderCellParams<string[]>) => (
    //     <Box sx={{width: "100%", height: "100%", display: "flex", alignItems:"center", justifyContent:"center", flexWrap: "wrap"}}>
    //       {params.value.map((queue: string, index: number) => (
    //         <Chip key={index} label={queue} style={{margin: "2px"}}/>
    //       ))}
    //     </Box>
    //   ),
    //   flex: 1,
    //   align: 'left',
    //   headerAlign: 'left',
    // },
    {
      field: "successfulJobs",
      headerName: "Successful Jobs",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "failedJobs",
      headerName: "Failed Jobs",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "workingTimeS",
      headerName: "Working time (seconds)",
      flex: 1,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => {
        return params.value.toFixed(2);
      },
    },
    {
      field: "birthDate",
      headerName: "Birth Date",
      flex: 1,
      align: "right",
      headerAlign: "right",
    },
    // {
    //   field: 'lastActive',
    //   headerName: 'Last Active',
    //   flex: 1,
    //   align: 'right',
    //   headerAlign: 'right',
    // },
  ];
  return <>{tableData && <ParchaDataGrid rows={tableData} columns={columns} sx={{ m: 2 }} />}</>;
};

export default JobStatusTable;
